import NotAuthenticatedUserHeader from "../NotAuthenticatedUserHeader/NotAuthenticatedUserHeader";
import AuthenticatedUserHeader from "../AuthenticatedUserHeader/AuthenticatedUserHeader";
import LanguagePicker from "../LanguagePicker/LanguagePicker";
import { useAuth } from "../../context/useAuth";
import { useState, useEffect, useRef } from "react";
import styles from "./AuthNLngContainer.module.scss";
import { useNavigate } from "react-router-dom";

export default function AuthNLngContainer() {
  const auth = useAuth();
  const { user } = auth;
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(user ? true : false);
  console.log("user => ", user);

  console.log("isAuthenticated => ", isAuthenticated);

  useEffect(() => {
    console.log("user in useEffect in AuthNLngContainer => ", user);
    if (user) {
      console.log("There is an user.");
      setIsAuthenticated(true);
    } else {
      console.log("There is no user.");
      setIsAuthenticated(false);
    }
  }, [user]);

  return (
    <div className={styles.authNLngContainer}>
      {isAuthenticated ? (
        <AuthenticatedUserHeader />
      ) : (
        <NotAuthenticatedUserHeader />
      )}
      <LanguagePicker />
    </div>
  );
}
