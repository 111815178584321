import styles from "./FilterChip.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export default function FilterChip({ label, btnHandler }) {
  return (
    <li className={styles.filterChip}>
      <span>{label}</span>
      <button className={styles.chipBtn} onClick={btnHandler}>
        <FontAwesomeIcon icon={faXmark} />
      </button>
    </li>
  );
}
