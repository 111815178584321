import { parseHashParams, invalidateSession } from "../../utils/APIUtils";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// Handles the redirect before sending the user to the login page

export default function PostVerifyEmail() {
  // Get the access token and refresh token from the URL hash
  const { access_token } = parseHashParams();
  // Get email and verified from the URL hash
  const [searchParams] = useSearchParams();
  const verified = searchParams.get("verified");
  const email = searchParams.get("email");
  const navigate = useNavigate();
  // Invalidate the session and refreshToken
  useEffect(() => {
    const invalidateSessionHandler = async () => {
      await invalidateSession(access_token);
      navigate(`/login?email=${email}&verified=${verified}`);
    };
    invalidateSessionHandler();
  }, []);

  return (
    <div>
      <h1>Post Verify Email</h1>
    </div>
  );
}
