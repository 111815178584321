import { getEventLinxGeneralBanner } from "../../utils/imageUtils";
export const transformPromoterLandingPageContent = (content) => {
  const pageContent = content.pageContent;

  if ("error" in pageContent) {
    return {
      error: "Promoter not found",
    };
  }

  const banner = pageContent.banner;
  const profilePic = pageContent.profilePic;
  const bannerURL = banner
    ? `${process.env.REACT_APP_IMG_BASE_URL}${banner}/public`
    : getEventLinxGeneralBanner();
  const profilePicURL = profilePic
    ? `${process.env.REACT_APP_IMG_BASE_URL}${profilePic}/public`
    : null;
  const numberOfEvents = content.eventList.totalCount;
  const numberOfEventsStr =
    numberOfEvents === 1 ? "1 Event" : `${numberOfEvents} Events`;

  const events = content.eventList.events.filter(
    (event) => event.performances.length > 0
  );

  return {
    description: content.pageContent.description,
    name: pageContent.name,
    socialMedia: pageContent.socialMedia,
    supportEmail: pageContent.supportEmail,
    numberOfEventsStr,
    events,
    bannerURL,
    profilePicURL,
  };
};
