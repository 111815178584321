import styles from "./EventsFilter.module.scss";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import { removeUnderlineFromString } from "../../utils/CommonUtils";

export default function CheckboxGroup({
  selectedTypes,
  onChangeChecked,
  value,
}) {
  const checked = selectedTypes.includes(value);

  return (
    <div className={styles.checkboxGroup}>
      <CustomCheckbox
        checked={checked}
        onChangeChecked={() => onChangeChecked(value)}
        variant="filterCheckbox"
      />
      <span>{removeUnderlineFromString(value)}</span>
    </div>
  );
}
