import { formatDateWithoutTime, maskString } from "../../../utils/CommonUtils";

export const transformClaimPartialDetails = (claimData) => {
  const status = claimData.refundStatus;
  let statusText;
  switch (status) {
    case "pending":
      statusText = "Refund Under Review";
      break;
    case "approved":
      statusText = "Refund Approved";
      break;
    case "rejected":
      statusText = "Refund Denied";
      break;
  }

  const ticketId = claimData.ticketId || null;
  const transactionId = claimData.transactionId || null;
  const requestDate = formatDateWithoutTime(claimData.createdAt) || null;

  if (ticketId && transactionId) {
    return {
      status,
      statusText,
      eventName: claimData.eventName,
      performanceName: claimData.performanceName,
      sectionName: claimData.sectionName,
      typeName: claimData.typeName,
      ticketId,
      transactionId,
      showingTicketId: status === "rejected" ? ticketId : maskString(ticketId),
      requestDate,
    };
  }
  return {
    status,
    statusText,
    eventName: claimData.eventName,
    performanceName: claimData.performanceName,
    sectionName: claimData.sectionName,
    typeName: claimData.typeName,
    requestDate,
  };
};

export const transformClaimDetails = (claimData, ticketId) => {
  const partialDetails = transformClaimPartialDetails(claimData);

  return {
    ...partialDetails,
    amountRefunded: claimData.amountRefunded,
    rejectReason: claimData.rejectReason,
    showingTicketId:
      claimData.refundStatus === "rejected" ? ticketId : maskString(ticketId),
  };
};

//Status Messages

const UNDER_REVIEW = () => {
  return (
    <p>
      This request is <strong>under review</strong> by the organizer. Once a
      decision is made, you will be informed and provided with all the details
      through this page.
    </p>
  );
};
const APPROVED = (amount) => {
  return (
    <p>
      Your request has been <strong>approved</strong>, and you will receive a
      refund of &nbsp;
      <strong>
        CAD$
        {amount}
      </strong>
      , after the deduction of applicable purchase fees.{" "}
      <strong>
        The amount will be returned to your original payment method.
      </strong>
    </p>
  );
};

const REJECTED = (rejectReason, contactOrganizationHandler) => {
  return (
    <>
      <p>
        Your request has been <strong>denied due to {rejectReason}</strong>.
        Your ticket <strong>will be reinstated</strong>, after which you will be
        able to use it for the respective event.
      </p>
      <p>
        If you need further information, please{" "}
        <button onClick={contactOrganizationHandler}>
          contact the event organization here
        </button>
      </p>
    </>
  );
};

export const transformClaimStatusData = (
  claimData,
  contactOrganizationHandler
) => {
  const refundStatus = claimData.refundStatus;
  const dataObjCreated = {
    date: formatDateWithoutTime(claimData.createdAt),
    statusColor: "grey",
    statusMessage: UNDER_REVIEW(),
  };
  switch (refundStatus) {
    case "pending":
      dataObjCreated.statusColor = "orange";
      return [dataObjCreated];
    case "approved":
      const dataObjApproved = {
        date: formatDateWithoutTime(claimData.updatedAt),
        statusColor: "green",
        statusMessage: APPROVED(claimData.amountRefunded),
      };
      return [dataObjApproved, dataObjCreated];
    case "rejected":
      const dataObjRejected = {
        date: formatDateWithoutTime(claimData.updatedAt),
        statusColor: "red",
        statusMessage: REJECTED(
          claimData.rejectReason,
          contactOrganizationHandler
        ),
      };
      return [dataObjRejected, dataObjCreated];
  }
};

export const transformContactOrganizerData = (claimData) => {
  return {
    promoterId: claimData.promoterId,
  };
};
