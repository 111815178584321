import styles from "./EventsFilter.module.scss";
import CustomRadioButton from "../CustomRadioButton/CustomRadioButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import CheckboxGroup from "./CheckboxGroup";

export default function CetegorySection({
  setShowCategory,
  showSearchRange,
  getFilterOptsClass,
  onCategoryChange,
  selectedCategories,
  categories,
}) {
  return (
    <section>
      <div className={styles.sectionHeader}>
        <h3>Category</h3>
        <button
          className={styles.showToggler}
          onClick={() => {
            setShowCategory((prev) => !prev);
          }}
        >
          {showSearchRange ? (
            <FontAwesomeIcon icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} />
          )}
        </button>
      </div>
      <div className={getFilterOptsClass("category")}>
        {categories.map((category) => (
          <CheckboxGroup
            value={category}
            onChangeChecked={onCategoryChange}
            key={category}
            selectedTypes={selectedCategories}
          />
        ))}
      </div>
    </section>
  );
}
