import { formatDateToAmericanStyle } from "../../utils/CommonUtils";

export const transformEventListCard = (event) => {
  console.log(
    "********* minStartTime in transformEventList => ",
    event.minStartTime
  );

  return {
    featureImage: event.featureImage,
    eventId: event.id,
    startDate: formatDateToAmericanStyle(event.minStartTime, true, true),
    shareCount: event.shareCount || 0,
    eventName: event.title,
    performances: event.performances,
  };
};
