import styles from "./EventPreview.module.scss";
import { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getEventDetails } from "../../utils/APIUtils";
import { loadImage, getShowingLanguage } from "../../utils/CommonUtils";
import EventPerformanceHeader from "../../components/EventPerformanceHeader/EventPerformanceHeader";
import { useLanguage } from "../../context/useLanguage";
import PerformanceCard from "../../components/PerformanceCard/PerformanceCard";
import SponsorCardsContainer from "../../components/SponsorCardsContainer/SponsorCardsContainer";
import AboutEventPerfomance from "../../components/AboutEventPerfomance/AboutEventPerfomance";
import ImagesSection from "../../components/ImagesSection/ImagesSection";
import VideoContainer from "../../components/VideoSection/VideoSection";
import SocialSection from "../../components/SocialSection/SocialSection";
import VIPSection from "../../components/VIPSection/VIPSection";
import { eventTransformData } from "./eventTransformData";
import Loading from "../../components/Loading/Loading";
import ResponseMessage from "../../components/ModalContents/ResponseMessage/ResponseMessage";
import { getFetchStatusIcon } from "../../utils/iconUtils";
import EventListCard from "../../components/EventListCard/EventListCard";
import SimilarEventsCard from "./SimilarEventsCard";

export default function EventPreview() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [banner, setBanner] = useState(null);
  const [eventImgs, setEventImgs] = useState([]);

  const [searchParams] = useSearchParams();
  const distance = searchParams.get("distance");
  const lat = searchParams.get("lat");
  const lng = searchParams.get("lng");

  // Fetch event details
  const {
    data: eventDetails,
    isFetching: isEventDetailsFetching,
    error: eventDetailsError,
  } = useQuery({
    queryKey: ["eventDetails", eventId],
    queryFn: async () => getEventDetails(eventId, distance, lat, lng),
  });

  // TODO: ADD ERROR HANDLER FOR FETCHING DATA

  // Load event images
  useEffect(() => {
    if (!isEventDetailsFetching && eventDetails) {
      console.log("It is not fetching");
      const descriptionImgURLs = [];
      const bannerURL = `${process.env.REACT_APP_IMG_BASE_URL}${eventDetails.mainImages[0]}/public`;
      loadImage(bannerURL, null, setBanner);

      eventDetails.description.images.slice(0, 4).forEach((imgId) => {
        const imgURL = `${process.env.REACT_APP_IMG_BASE_URL}${imgId}/public`;
        loadImage(imgURL, null, (img) => descriptionImgURLs.push(img));
      });

      setEventImgs(descriptionImgURLs);
    }
  }, [isEventDetailsFetching, eventDetails, eventId]);
  if (!isEventDetailsFetching && !eventDetails) {
    navigate("/error");
  }

  if (isEventDetailsFetching) {
    return <Loading />;
  }

  if (!eventDetails || eventDetailsError) {
    return (
      <ResponseMessage
        icon={getFetchStatusIcon("error")}
        title="Error!"
        message
      />
    );
  }

  const showingLanguage = getShowingLanguage(
    eventDetails?.contentLanguages,
    language
  );

  const {
    title,
    startTime,
    startingPrice,
    sponsors,
    promoterName,
    promoterRoute,
    aboutEvent,
    videos,
    socials,
    performances,
    promoterId,
    similarEvents,
  } =
    !isEventDetailsFetching && eventDetails && eventTransformData(eventDetails);

  return (
    <div className={styles.eventPreview}>
      <div className={styles.eventContainer}>
        <EventPerformanceHeader
          banner={banner}
          title={title[showingLanguage]}
          startTime={startTime}
          shareFunction={() => console.log("share")}
          startingPrice={startingPrice}
          sponsors={sponsors}
          promoterName={promoterName}
          promoterRoute={promoterRoute}
          promoterId={promoterId}
          eventId={eventId}
          language={showingLanguage}
        />
        <AboutEventPerfomance
          content={aboutEvent[showingLanguage]}
          type="event"
        />
        {eventImgs.length > 0 && <ImagesSection images={eventImgs} />}
        {videos.length > 0 && <VideoContainer videoId={videos[0].id} />}
        {socials.length > 0 && <SocialSection socialMediaAccounts={socials} />}
        <VIPSection />
        <section className={styles.performances}>
          <div className={styles.performancesContainer}>
            <div className={styles.performancesCards}>
              <h3>Performances</h3>
              <ul className={styles.performancesCardsContainer}>
                {performances.map((performance) => (
                  <PerformanceCard
                    key={performance.id}
                    performance={performance}
                    imgId={performance.featureImage[0]}
                  />
                ))}
              </ul>
            </div>
          </div>
        </section>
        <section className={styles.similarEvents}>
          <div className={styles.similarEventsContainer}>
            <div className={styles.similarEventsCards}>
              <h3>Similar Events</h3>
              <ul className={styles.similarEventsCardsContainer}>
                {similarEvents.map((event) => (
                  <SimilarEventsCard key={event.id} event={event} />
                ))}
              </ul>
            </div>
          </div>
        </section>
        {sponsors && sponsors.length > 0 && (
          <section className={styles.sponsors}>
            <SponsorCardsContainer sponsors={sponsors} />
          </section>
        )}
      </div>
    </div>
  );
}
