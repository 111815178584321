import React, { useState, useRef } from "react";
import styles from "./CustomDropdown.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import useOutsideClick from "../../utils/useOutsideClick";

export default function CustomDropdown({
  optionsObj,
  title,
  onSelect,
  selectedOption,
  variant,
}) {
  const [showModal, setShowModal] = useState(false);
  const dropdownRef = useRef();
  const toggleRef = useRef();

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSelectionChange = (option) => {
    onSelect(optionsObj[option]);
    handleCloseModal();
  };

  // Use the custom hook to handle outside click of the dropdown
  useOutsideClick(dropdownRef, (event) => {
    if (
      (showModal,
      toggleRef.current && !toggleRef.current.contains(event.target))
    ) {
      setShowModal(false);
    }
  });

  const getComponentClasses = () => {
    if (variant) {
      return `${styles.customDropdown} ${styles[variant]}`;
    } else {
      return styles.customDropdown;
    }
  };

  const getChosenOpt = () => {
    switch (variant) {
      case "eventSort":
        return `Sort by: ${selectedOption.title}`;
      default:
        return `${title} ${selectedOption.title}`;
    }
  };

  return (
    <div className={getComponentClasses()}>
      <button
        className={styles.btn}
        onClick={() => {
          showModal ? handleCloseModal() : handleOpenModal();
        }}
        ref={toggleRef}
      >
        <span>{title}</span>
      </button>

      <div
        className={`${styles.modal} ${showModal ? styles.active : ""}`}
        ref={dropdownRef}
      >
        <div className={styles.btnsContainer}>
          <div className={styles.chosenOpt}>
            <span>{getChosenOpt()}</span>
          </div>
          {optionsObj.map((opt, index) => {
            return (
              opt.title !== selectedOption.title && (
                <button
                  className={styles.changeBtn}
                  onClick={() => handleSelectionChange(index)}
                  key={`option-${index}`}
                >
                  {opt.title}
                </button>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
}
