import styles from "./Loading.module.scss";
import Spinner from "../ModalContents/Spinner/Spinner";

export default function Loading() {
  return (
    <div className={styles.loading}>
      <Spinner />
    </div>
  );
}
