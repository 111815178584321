import styles from "./StatusDetails.module.scss";
import {
  transformClaimStatusData,
  transformContactOrganizerData,
} from "./transformClaimsData";
import ContactOrganizer from "../../../components/ModalContents/ContactOrganizer/ContactOrganizer";
import { useModal } from "../../../context/useModal";

export default function StatusDetails({
  claimData,
  showingLanguage,
  transactionId,
  ticketId,
}) {
  const { openModal, closeModal } = useModal();
  console.log("claimData in StatusDetails", claimData);

  const { promoterId } = transformContactOrganizerData(claimData);
  console.log("promoterId", promoterId);
  const openContactOrganizer = () => {
    const messageTitle = `Denied Claim - Order#: ${transactionId} - Ticket#: ${ticketId}`;
    openModal(
      <ContactOrganizer
        promoterId={promoterId}
        showingLanguage={showingLanguage}
        onCancel={closeModal}
        orderId={transactionId}
        messageObj={{ messageTitle }}
      />,
      "whiteFullWidth"
    );
  };

  const handleContactOrganizer = () => {
    openContactOrganizer();
  };

  const data = transformClaimStatusData(claimData, handleContactOrganizer);
  return (
    <div className={styles.statusDetails}>
      {data.map((item, index) => (
        <div className={styles.itemContainer} key={index}>
          <div className={styles.item}>
            <div className={styles[item.statusColor]}></div>
            <div className={styles.data}>
              <span className={styles.date}>{item.date}</span>
              {item.statusMessage}
            </div>
          </div>
          {index !== data.length - 1 && <div className={styles.divider}></div>}
        </div>
      ))}
    </div>
  );
}
