import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import CustomButton from "../../../components/CustomButton/CustomButton";
import styles from "./ContactInfo.module.scss";
import { updateContactInfo } from "../../../utils/APIUtils";
import validateContactInfo from "./contactInfoValidator";
import { useAuth } from "../../../context/useAuth";
import AddressFormSection from "../../../components/AddressFormSection/AddressFormSection";
import { useModal } from "../../../context/useModal";
import ContactInfoSavedModal from "../../../components/ContactInfoSavedModal/ContactInfoSavedModal";
import { ADDRESS_TYPES, EMPTY_ADDRESS } from "../../../constants/index";
import {
  formatAddress,
  cleanUserObject,
  deleteNullValues,
  deleteAddressesIfEmpty,
} from "../../../utils/CommonUtils";
import BasicInfoSection from "./BasicInfoSection";
import ImagePicker from "../../../components/ImagePicker/ImagePicker";
import { setItem } from "../../../utils/LocalStorageUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Spinner from "../../../components/ModalContents/Spinner/Spinner";
import Loading from "../../../components/Loading/Loading";

/**
 * The ContactInfo function is the main component for handling the contact
 * information of the user.
 *
 * @return {JSX.Element} The JSX element representing the ContactInfo component.
 */
export default function ContactInfo() {
  const navigate = useNavigate();
  const auth = useAuth();
  const { user } = auth;
  const { openModal, closeModal } = useModal();

  const queryClient = useQueryClient();

  const {
    mutateAsync: changeContactInfo,
    isPending: isChangeContactInfoPending,
    error: changeContactInfoError,
    isError: isChangeContactInfoError,
  } = useMutation({
    mutationFn: async (data) => updateContactInfo(data),
    onSuccess: (response) => {
      queryClient.setQueryData(["userDetails"], null);

      // Mark query as invalid
      queryClient.invalidateQueries({ queryKey: ["userDetails"] });

      console.log("Contact Info Updated Successfully");
      openModal(<ContactInfoSavedModal />, "contactInfoSaved");
      setTimeout(() => {
        closeModal();
      }, 2000);
    },
    onError: (error) => {
      console.error("Error updating contact info: ", error);
      setErrors((prebErrors) => {
        return {
          ...prebErrors,
          updating: "Something went wrong, please try again",
        };
      });
    },
  });

  // Make a copy of the user object to make the changes on it before saving
  const userCopy = structuredClone(user);
  if (userCopy) {
    cleanUserObject(userCopy);
  }

  const [userDetails, setUserDetails] = useState(userCopy);
  const [wasEdited, setWasEdited] = useState(false);
  const [isBillingEqualToShipping, setIsBillingEqualToShipping] =
    useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (user && !wasEdited) {
      const userCopy = structuredClone(user);
      console.log("userCopy in useEffect: ", userCopy);
      cleanUserObject(userCopy);
      console.log(
        "userCopy in useEffect after deleting properties: ",
        userCopy
      );
      setUserDetails(structuredClone(userCopy));
    }
  }, [user]);

  useEffect(() => {
    if (isBillingEqualToShipping) {
      setUserDetails((prev) => ({
        ...prev,
        shippingAddress: prev.billingAddress,
      }));
    }
  }, [isBillingEqualToShipping]);

  // If the user is not logged in, redirect to the login page
  if (!user) {
    navigate("/login");
    return null;
  }

  /**
   * Validates the contact information.
   *
   * @return {boolean} Returns true if the contact information is valid, false otherwise.
   */
  const validate = () => {
    const error = validateContactInfo(userDetails);
    if (Object.entries(error).length > 0) {
      console.log("errors: ", error);
      setErrors(error);
      return false;
    }
    return true;
  };

  const handleChangeBillingAddress = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      billingAddress: { ...prevUserDetails.billingAddress, [name]: value },
    }));
    if (isBillingEqualToShipping) {
      setUserDetails((prevUserDetails) => {
        return {
          ...prevUserDetails,
          shippingAddress: {
            ...prevUserDetails.billingAddress,
          },
        };
      });
    }
  };

  /**
   * Handles the change event for the shipping address fields.
   *
   * @param {ChangeEvent<HTMLInputElement>} e - The change event.
   */
  const handleChangeShippingAddress = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      shippingAddress: {
        shippingAddress: { ...prevUserDetails.shippingAddress, [name]: value },
      },
    }));
  };

  // Handle address autocomplete
  const setAutocompleteValue = (value, addressType) => {
    const address = formatAddress(value, addressType);

    if (addressType === ADDRESS_TYPES.BILLING) {
      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        billingAddress: address,
        shippingAddress: isBillingEqualToShipping
          ? address
          : prevUserDetails.shippingAddress,
      }));
    } else if (addressType === ADDRESS_TYPES.SHIPPING) {
      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        shippingAddress: address,
      }));
    }
  };

  // Edits the address depending on the address type
  const onAddressEdit = (type) => {
    if (type === ADDRESS_TYPES.BILLING) {
      setUserDetails((prev) => ({ ...prev, billingAddress: EMPTY_ADDRESS }));
    } else if (type === ADDRESS_TYPES.SHIPPING) {
      setUserDetails((prev) => ({ ...prev, shippingAddress: EMPTY_ADDRESS }));
    }
  };

  // Handles the form submission event.
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userDetails) {
      // Create user details object
      if (validate()) {
        // Send the user details to the api
        const userDetailsCopy = structuredClone(userDetails);
        deleteAddressesIfEmpty(userDetailsCopy, isBillingEqualToShipping);
        // Delete Null Values:
        deleteNullValues(userDetailsCopy);
        console.log("userDetailsCopy => ", userDetailsCopy);

        await changeContactInfo(userDetailsCopy);
      }
    } else {
      navigate("/login");
    }
  };

  const handleUserDetailsChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevUserDetails) => {
      return {
        ...prevUserDetails,
        [name]: value,
      };
    });
  };

  const setProfileImage = (profileImage) => {
    setUserDetails((prevUserDetails) => {
      return {
        ...prevUserDetails,
        profilePictureUrl: profileImage,
      };
    });
  };

  // Show loading spinner while isCancelAccountPending
  if (isChangeContactInfoPending) {
    return <Loading />;
  }

  return (
    <div className={styles.contactInfo}>
      <div className={styles.contactInfoContainer}>
        <div className={styles.titleContainer}>
          <h2>Contact Information</h2>
          <p>Update your account details anytime</p>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <section className={styles.imgContainer}>
            <h4>Profile picture</h4>
            <ImagePicker
              profileImage={userDetails.profilePictureUrl}
              onImageChange={setProfileImage}
            />
          </section>
          <BasicInfoSection
            styles={styles}
            userDetails={userDetails}
            handleUserDetailsChange={handleUserDetailsChange}
            errors={errors}
            setErrors={setErrors}
          />
          <section className={styles.addressContainer}>
            <AddressFormSection
              sectionTitle="Billing Address"
              address={userDetails.billingAddress}
              onAddressChange={handleChangeBillingAddress}
              errors={errors}
              setErrors={setErrors}
              onAddressEdit={onAddressEdit}
              setAutocompleteValue={setAutocompleteValue}
              addressType={ADDRESS_TYPES.BILLING}
              setWasEdited={setWasEdited}
            />
            <div className={styles.shippingAddressCheckbox}>
              <CustomCheckbox
                onChangeChecked={() =>
                  setIsBillingEqualToShipping(!isBillingEqualToShipping)
                }
                checked={isBillingEqualToShipping}
                variant="semiDark"
              />
              <label>Shipping address is same as the billing address</label>
            </div>

            {!isBillingEqualToShipping && (
              <AddressFormSection
                sectionTitle="Shipping Address"
                address={userDetails.shippingAddress}
                onAddressChange={handleChangeShippingAddress}
                errors={errors}
                setErrors={setErrors}
                onAddressEdit={onAddressEdit}
                setAutocompleteValue={setAutocompleteValue}
                addressType={ADDRESS_TYPES.SHIPPING}
                setWasEdited={setWasEdited}
              />
            )}
            <div className={styles.btnContainer}>
              <CustomButton
                type="submit"
                className={styles.submitButton}
                title="Save"
              />
            </div>
          </section>
        </form>
      </div>
    </div>
  );
}
