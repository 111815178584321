import React, { useState, useEffect, useContext, createContext } from "react";
import { getUserDetails } from "../utils/APIUtils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { setItem, removeItem } from "../utils/LocalStorageUtils";

// Empty address
const emptyAddress = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  province: "",
  postalCode: "",
  country: "",
};

/**
 * The auth context is a React context that holds the auth object. It is created
 * using the createContext function from the React library.
 *
 * @type {React.Context<Object>}
 */
const authContext = createContext();

/**
 * ProvideAuth is a React component that wraps your app and makes the auth object
 * available to any child component that calls useAuth().
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {React.ReactNode} - The rendered component.
 */

/**
 * ProvideAuth is a React component that wraps your app and makes the auth object
 * available to any child component that calls useAuth().
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {React.ReactNode} - The rendered component.
 */

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

/**
 * Hook for child components to get the auth object and
 * re-render when it changes.
 *
 * @return {Object} The auth object.
 */
export const useAuth = () => {
  return useContext(authContext);
};

/**
 * Hook that provides the auth object and handles state.
 *
 * @return {Object} The auth object.
 */
function useProvideAuth() {
  let emailVerificationCode = null;

  // const supabase = createSupabaseClient(
  //   process.env.REACT_APP_SUPABASE_URL,
  //   process.env.REACT_APP_SUPABASE_ANON_KEY
  // );

  /* 
    Fetches the user details from the API
    It passes the userDetails as a parameter to the getUserDetails function so
    if there is no need for a refetch, it will return the cached value.
  */
  const {
    data: userDetails,
    error: userDetailsError,
    isFetching: isFetchingUserDetails,
  } = useQuery({
    queryKey: ["userDetails"],
    queryFn: async () => {
      const queryCache = queryClient.getQueryData(["userDetails"]);
      console.log("**************** queryCache: ", queryCache);

      return getUserDetails(queryCache);
    },
    refetchInterval: 1000 * 60 * 5, //Refetch every 5 minutes
    refetchOnWindowFocus: true,
  });
  const queryClient = useQueryClient();

  console.log("**************** userDetails: ", userDetails);
  if (userDetails && "sessionExpirationDate" in userDetails) {
    console.log("sessionExpirationDate => ", userDetails.sessionExpirationDate);

    setItem("sessionExpirationDate", userDetails.sessionExpirationDate);
    setItem("accessToken", userDetails.accessToken);
  } else {
    // Remove the sessionExpirationDate and accessToken from the local storage
    removeItem("sessionExpirationDate");
    removeItem("accessToken");
  }

  const formatAndSetUser = (user) => {
    if (!user) {
      return null;
    }

    if (user.message === "NOT_AUTHENTICATED") {
      return null;
    }

    if (user.billingAddress === null) {
      user.billingAddress = structuredClone(emptyAddress);
    }
    if (user.shippingAddress === null) {
      user.shippingAddress = structuredClone(emptyAddress);
    }
    return user;
  };

  const user = formatAndSetUser(userDetails);

  console.log("**************** user: ", user);

  /**
   * Returns the user object, a function to set the user object, and a logout function.
   *
   * @return {Object} An object containing the user object, a function to set the user object, and a logout function.
   * @property {Object} user - The user object.
   * @property {function} setUser - A function to set the user object.
   * @property {function} logout - A function to log the user out.
   */
  return {
    user,
    formatAndSetUser,
  };
}
