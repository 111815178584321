import { useEffect, useState } from "react";
import styles from "./EventListCard.module.scss";
import CustomButton from "../CustomButton/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ViewTicketsBtnContent from "../ViewTicketsBtnContent/ViewTicketsBtnContent";
import {
  getStartingPrice,
  formatDate,
  formatDateToAmericanStyle,
  getLanguageArray,
  getShowingLanguage,
} from "../../utils/CommonUtils";
import { useLanguage } from "../../context/useLanguage";
import { loadImage } from "../../utils/CommonUtils";
import shareIcon from "../../assets/share.svg";
import { transformEventListCard } from "./transformEventListCardData";

// Helper function to get the starting price of an event
// When it was develped the structure of the event hasn't beed finished yet

export default function EventListCard({ event, distance, location }) {
  console.log("event in EventListCard => ", event);

  // const [showMore, setShowMore] = useState(false);
  // TODO: Get EventType and Venue data
  const [thumbnail, setThumbnail] = useState(null);
  const {
    featureImage,
    eventId,
    startDate,
    shareCount,
    eventName,
    // performances,
  } = transformEventListCard(event);

  useEffect(() => {
    const imgURL = `${process.env.REACT_APP_IMG_BASE_URL}${featureImage[0]}/public`;
    loadImage(imgURL, null, setThumbnail);
  }, []);

  const navigate = useNavigate();
  const { language } = useLanguage();
  const languageArray = getLanguageArray(eventName);
  const showingLanguage = getShowingLanguage(languageArray, language);
  const handleGoToEvent = async () => {
    console.log("eventId => ", eventId);
    console.log("handleViewTicketsClick called for eventId => ", eventId);
    navigate(
      `/events/${eventId}?distance=${distance * 1000}&lat=${
        location.geometry.lat
      }&lng=${location.geometry.lng}`
    );
  };

  // Handle share event
  const handleShare = () => {
    console.log("handleShare called!");
  };

  return (
    <li className={styles.eventListCard}>
      <div className={styles.imgContainer}>
        <img
          className={styles.thumbnail}
          src={thumbnail}
          alt="event thumbnail"
        />
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.dataContainer}>
          <h3 className={styles.eventName}>{eventName[showingLanguage]}</h3>
          <span className={styles.eventType}>Paid Event</span>
          <div className={styles.middleLine}>
            <span className={styles.detail}>{startDate}</span>
            <span className={styles.detail}>VENUE INFO</span>
          </div>
          <span
            className={styles.shareCount}
          >{`${shareCount} People shared this event`}</span>
        </div>
        <div className={styles.btnsContainer}>
          <button className={styles.shareBtn} onClick={handleShare}>
            <img src={shareIcon} alt="share icon" />
            {/* <FontAwesomeIcon icon={faShareNodes} /> */}
          </button>
          <div className={styles.detailsBtnContainer}>
            <CustomButton
              title="Details"
              variant="eventListCardBtn"
              clickHandler={handleGoToEvent}
            />
          </div>
        </div>
      </div>
    </li>
  );
}
