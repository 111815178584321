import styles from "./EventDashboard.module.scss";
import rightArrow from "../../assets/right-arrow.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import { useState, useRef, useEffect, useCallback } from "react";
import {
  getEventList,
  getEventsCategories,
  getEventTypes,
} from "../../utils/APIUtils";
import { useQuery } from "@tanstack/react-query";
import EventListCard from "../../components/EventListCard/EventListCard";
import EventsFilter from "../../components/EventsFilter/EventsFilter";
import useOutsideClick from "../../utils/useOutsideClick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { getRoughLocation } from "../../utils/LocalizationUtils";
import Loading from "../../components/Loading/Loading";
import { Link } from "react-router-dom";
import { transformEventDashboardData } from "./transformEventDashboardData";
import sliders from "../../assets/sliders.svg";
import sortIcon from "../../assets/sort.svg";
import ChipsContainer from "./ChipsContainer";
import SearchInputText from "./SearchInputText";

const SORT_OPTIONS = [
  { title: "Date", value: "date" },
  { title: "A-Z", value: "a-z" },
  { title: "Lowest Price", value: "lowest-price" },
  { title: "Latest", value: "latest" },
];

const FORMATS = ["All", "In-person", "Stream", "Hybrid"];
// const CATEGORIES = ["All", "Concert", "Festival", "Theatre", "Sports"];
const ADMISSION_TYPES = ["All", "Free", "Paid"];
const DATES = ["All", "This Weekend", "Weekdays", "Weekends", "Date Range"];
const RANGE = [10, 500];

const FilterBtnContent = ({ filter, sort }) => {
  if (filter) {
    return (
      <span className={styles.filterBtnContent}>
        <img src={sliders} alt="filter icon" />
        Filter
      </span>
    );
  }
  if (sort) {
    return (
      <span className={styles.filterBtnContent}>
        <img src={sortIcon} alt="sort icon" />
        Sort By
      </span>
    );
  }
};

// const LOCATIONS =

export default function EventDashboard() {
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0]);
  const [showFilter, setShowFilter] = useState(false);
  const [format, setFormat] = useState(FORMATS[0]);
  const [categories, setCategories] = useState(["All"]); // Starts with all, before fetching the categories
  const [admissionType, setAdmissionType] = useState(ADMISSION_TYPES[0]);
  const [dateType, setDateType] = useState(DATES[0]);
  const [searchText, setSearchText] = useState("");
  const [location, setLocation] = useState(null);
  const [selectedEventTypes, setSelectedEventTypes] = useState(["All"]);
  const [dateRange, setDateRange] = useState(null);
  const [showSearchRange, setShowSearchRange] = useState(false);
  const [distance, setDistance] = useState(250);

  // const updateSearchText = useCallback((value) => {
  //   setSearchText(value);
  // }, []);

  const filterRef = useRef(null);
  // const focusRef = useRef(null);

  useOutsideClick(filterRef, () => setShowFilter(false));

  useEffect(() => {
    if (!location) {
      getRoughLocation().then((location) => {
        setLocation(location);
      });
    }
  }, []);

  const {
    status,
    data: eventsData,
    error: eventsError,
    isFetching: isEventsFetching,
  } = useQuery({
    queryKey: [
      "events",
      {
        format,
        categories,
        admissionType,
        dateType,
        searchText,
        location,
        sortOption: sortOption.value,
        distance,
        eventTypes: selectedEventTypes,
        dateRange,
      },
    ],
    queryFn: () =>
      getEventList({
        format,
        categories,
        admissionType,
        dateType,
        searchText,
        location,
        sortBy: sortOption.value,
        distance,
        eventTypes: selectedEventTypes,
        dateRange,
      }),
  });

  const {
    data: eventsCategories,
    error: eventsCategoriesError,
    isFetching: isEventsCategoriesFetching,
  } = useQuery({
    queryFn: () => getEventsCategories(),
  });

  // TODO: ADD ERROR HANDLER FOR FETCHING DATA
  // Error handling: display the error message if something went wrong with the fetch.
  if (eventsError) {
    return <div>Error fetching events: {eventsError.message}</div>;
  }

  const handleSearch = () => {
    console.log("Search text: - Still not implemented", searchText);
  };

  const handleCategoriesChange = (value) => {
    if (value === "All") {
      setCategories([value]);
    } else {
      if (categories.includes(value)) {
        setCategories((prevCategories) => {
          if (prevCategories.length === 1) {
            return ["All"];
          }
          return prevCategories.filter((category) => category !== value);
        });
      } else {
        setCategories((prevCategories) => {
          if (prevCategories.includes("All")) {
            prevCategories.pop();
          }
          return [...prevCategories, value];
        });
      }
    }
  };
  const handleEventTypeChange = (value) => {
    if (value === "All") {
      setSelectedEventTypes([value]);
    } else {
      if (selectedEventTypes.includes(value)) {
        setSelectedEventTypes((prevSelectedTypes) => {
          if (prevSelectedTypes.length === 1) {
            return ["All"];
          }
          return prevSelectedTypes.filter((type) => type !== value);
        });
      } else {
        setSelectedEventTypes((prevTypes) => {
          if (prevTypes.includes("All")) {
            prevTypes.pop();
          }
          return [...prevTypes, value];
        });
      }
    }
  };

  const handleChangeLocationClick = () => {
    setShowSearchRange(true);
    setShowFilter(true);
  };

  if (isEventsFetching || isEventsCategoriesFetching) {
    return <Loading />;
  }

  // Transform the data
  const { events, locationString, totalCount } = transformEventDashboardData(
    eventsData,
    location
  );

  return (
    <div className={styles.eventDashboard}>
      <div className={styles.eventDashboardContainer}>
        <div className={styles.topContainer}>
          <div className={styles.pathContainer}>
            <Link className={styles.pathLink} to="/">
              Home
            </Link>
            <span className={styles.arrow}>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
            <Link className={styles.pathLink} to="/events">
              Events
            </Link>
          </div>
          <div className={styles.dashboardHeader}>
            <div className={styles.titleContainer}>
              <h2 className={styles.title}>Events</h2>
              <h4 className={styles.locationLabel}>
                Search Events in <strong>{locationString}</strong>
              </h4>
            </div>
            <div className={styles.searchContainer}>
              <div className={styles.search}>
                <SearchInputText
                  onSetInputText={setSearchText}
                  inputInitialValue={searchText}
                />
              </div>
              <button
                className={styles.changeLocationBtn}
                onClick={handleChangeLocationClick}
              >
                Change Location
              </button>
            </div>
          </div>
        </div>
        <section className={styles.dataSection}>
          <div
            className={`${styles.filtersContainer} ${
              showFilter ? styles.showFilter : ""
            }`}
            ref={filterRef}
          >
            <EventsFilter
              formats={FORMATS}
              selectedFormat={format}
              onFormatChange={setFormat}
              categories={["All", ...eventsCategories.categories]}
              selectedCategories={categories}
              onCategoryChange={handleCategoriesChange}
              admissionTypes={ADMISSION_TYPES}
              selectedAdmissionType={admissionType}
              onAdmissionTypeChange={setAdmissionType}
              datesOptions={DATES}
              selectedDateOpt={dateType}
              onDateOptChange={setDateType}
              dateRange={dateRange}
              onDateRangeChange={setDateRange}
              onLocationSet={setLocation}
              setShowFilter={setShowFilter}
              eventTypes={[
                { category: "All", types: [] },
                ...eventsCategories.types,
              ]}
              selectedEventTypes={selectedEventTypes}
              onEventTypeChange={handleEventTypeChange}
              showSearchRange={showSearchRange}
              setShowSearchRange={setShowSearchRange}
              onDistanceChange={setDistance}
              distance={distance}
              range={RANGE}
            />
          </div>
          <div className={styles.dataContainer}>
            <div className={styles.dataHeaderGroup}>
              <span className={styles.ticketsCount}>
                {
                  <>
                    We've found{" "}
                    <strong>
                      {totalCount} {events.totalCount === 1 ? "item" : "items"}
                    </strong>
                  </>
                }
              </span>
              <div className={styles.sortContainer}>
                <div className={styles.btnContainer}>
                  <CustomButton
                    title={<FilterBtnContent filter={true} />}
                    variant={"eventFilter"}
                    clickHandler={() => setShowFilter(!showFilter)}
                  />
                </div>

                <CustomDropdown
                  optionsObj={SORT_OPTIONS}
                  title={<FilterBtnContent sort={true} />}
                  onSelect={setSortOption}
                  selectedOption={sortOption}
                  variant="eventSort"
                />
              </div>
            </div>
            <ChipsContainer
              dateType={dateType}
              dateRange={dateRange}
              setDateType={setDateType}
              admissionType={admissionType}
              setAdmissionType={setAdmissionType}
              format={format}
              setFormat={setFormat}
              categories={categories}
              onCategoriesChange={handleCategoriesChange}
              eventTypes={selectedEventTypes}
              onEventTypesChange={handleEventTypeChange}
            />
            <ul className={styles.eventList}>
              {events.map((event) => {
                return (
                  <EventListCard
                    event={event}
                    key={event.id}
                    distance={distance}
                    location={location}
                  />
                );
              })}
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
}
