import {
  formatDateToAmericanStyle,
  formatDateToFilter,
  getEventVenue,
  getStartingDate,
  getStartingPrice,
} from "../../utils/CommonUtils";
export const eventTransformData = (data) => {
  console.log("eventTransformData data => ", data);
  let promoterRoute = null;
  if (data.promoter.customPath) {
    promoterRoute = `/${data.promoter.customPath}`;
  } else {
    promoterRoute = `/promoter/${data.promoterId}`;
  }

  console.log("✅ similarEvents => ", data.similarEvents);

  const transformedData = {
    title: data.title,
    startTime: getStartingDate(data.performances),
    startingPrice: getStartingPrice(data.performances),
    sponsors: data.sponsors,
    promoterName: data.promoter.name,
    promoterRoute,
    promoterId: data.promoterId,
    aboutEvent: data.description?.content || null,
    videos: data.description?.videos || null,
    socials: data.socialMediaAccounts,
    performances: data.performances,
    similarEvents: data.similarEvents.events,
  };

  return transformedData;
};

export const transformSimilarEventsCard = (event) => {
  const eventId = event.id;
  const eventTitle = event.title;
  const eventImage = event.featureImage[0];
  const rawEventStartTime = getStartingDate(event.performances);
  const eventStartTime = formatDateToAmericanStyle(
    rawEventStartTime.toISOString(),
    true,
    true
  );
  const eventStartingPrice = getStartingPrice(event.performances);
  const eventVenue = getEventVenue(event.performances);

  console.log("🗓️ event startTime => ", eventStartTime);

  return {
    eventId,
    eventTitle,
    eventImage,
    eventStartTime,
    eventStartingPrice,
    eventVenue,
  };
};
