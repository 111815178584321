import styles from "./TicketInfo.module.scss";
import { useLocation } from "react-router-dom";

import { transformTicketData } from "./ordersTransformation";
import ClaimDetails from "../Claims/ClaimDetails";
import { useModal } from "../../../context/useModal";

// Shows the ticket info for each ticket of the order
export default function TicketInfo({
  ticketInfo,
  showingLanguage,
  onViewTicket,
  eventName,
  transactionId,
}) {
  const location = useLocation();
  const { pathname } = location;
  const { openModal } = useModal();
  console.log("ticketInfo in TicketInfo =>", ticketInfo);

  // Transform the ticket data
  const ticketInfoTransformed = transformTicketData({
    ...ticketInfo,
  });

  // Get the transformed data
  const {
    performanceName,
    sectionName,
    typeName,
    ticketId,
    isRefundable,
    performanceId,
    ticketStatus,
    showingStatus,
    classTag,
    actionBtnText,
    actionBtnBehavior,
  } = ticketInfoTransformed;

  const handleShowRefundInfo = () => {
    console.log("handleShowRefundInfo");
    openModal(
      <ClaimDetails
        ticketId={ticketInfo.ticketId}
        transactionId={transactionId}
      />,
      "whiteFullWidth"
    );
  };

  const handleActionBtnClick = () => {
    console.log("handleActionBtnClick");
    switch (actionBtnBehavior) {
      case "viewTicket":
        onViewTicket([ticketId], pathname);
        break;
      case "refundInfo":
        handleShowRefundInfo();
        break;
      default:
        break;
    }
  };

  const statusClass = `${styles.ticketStatus} ${styles[classTag]}`;
  const actionBtnClass = `${styles.actionBtn} ${styles[classTag]}`;
  console.log("classTag =>", classTag);

  return (
    <li className={styles.ticketInfo}>
      <div className={styles.ticketNumberContainer}>
        <div className={styles.ticketId}>
          <span className={styles.ticketInfoHeader}>Ticket #</span>
          <span className={styles.ticketInfoData}>
            {ticketInfoTransformed.ticketId}
          </span>
        </div>

        {actionBtnText && (
          <button onClick={handleActionBtnClick} className={actionBtnClass}>
            {actionBtnText}
          </button>
        )}
        {/* TODO: ADD THE RIGHT LABEL TO THE BUTTON */}
      </div>
      <span className={statusClass}>{showingStatus}</span>
      {/* TODO: ADD THE RIGHT LABEL TO THE STATUS */}
      <div className={styles.ticketInfoContainer}>
        <div className={styles.ticketInfoCol}>
          <span className={styles.ticketInfoHeader}>Performance</span>
          <span className={styles.ticketInfoData}>
            {performanceName[showingLanguage]}
          </span>
        </div>
        <div className={styles.ticketInfoCol}>
          <span className={styles.ticketInfoHeader}>Section</span>
          <span className={styles.ticketInfoData}>
            {sectionName[showingLanguage]}
          </span>
        </div>
        <div className={styles.ticketInfoCol}>
          <span className={styles.ticketInfoHeader}>Type</span>
          <span className={styles.ticketInfoData}>
            {typeName[showingLanguage]}
          </span>
        </div>
      </div>
    </li>
  );
}
