import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faXTwitter,
  faFacebookF,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faTriangleExclamation,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

export const getSocialMediaIcon = (platform) => {
  switch (platform) {
    case "facebook":
      return <FontAwesomeIcon icon={faFacebookF} />;
    case "x":
      return <FontAwesomeIcon icon={faXTwitter} />;
    case "instagram":
      return <FontAwesomeIcon icon={faInstagram} />;
    case "tiktok":
      return <FontAwesomeIcon icon={faTiktok} />;
    case "youtube":
      return <FontAwesomeIcon icon={faYoutube} />;
  }
};

export const getFetchStatusIcon = (status) => {
  switch (status) {
    case "error":
      return <FontAwesomeIcon icon={faTriangleExclamation} />;
    case "success":
      return <FontAwesomeIcon icon={faThumbsUp} />;
  }
};
