import React, { useRef, useEffect, useState } from "react";

const InitialsAvatar = ({
  name,
  size = 190,
  bgColor = "#27aae1",
  textColor = "#ffffff",
}) => {
  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState("");

  const getInitials = (name) => {
    console.log("name in getInitials", name);

    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .slice(0, 2) // Get first two initials
      .join("");
  };

  useEffect(() => {
    if (!name) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const initials = getInitials(name);

    // Set canvas dimensions
    canvas.width = size;
    canvas.height = size;

    // Draw background
    ctx.fillStyle = bgColor;
    ctx.fillRect(0, 0, size, size);

    // Draw initials
    ctx.font = `${size / 2}px Arial`; // Dynamically scale font size
    ctx.fillStyle = textColor;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(initials, size / 2, size / 2 + size / 32);

    // Convert to data URL
    setImageSrc(canvas.toDataURL());
  }, [name, size, bgColor, textColor]);

  return (
    <>
      <canvas ref={canvasRef} style={{ display: "none" }} />
      {imageSrc && (
        <img
          src={imageSrc}
          alt={`Avatar for ${name}`}
          width={size}
          height={size}
        />
      )}
    </>
  );
};

export default InitialsAvatar;
