import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./LocationSearch.module.scss";

const CitySearchInput = ({ onSelectCity }) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  //   const [isCitySelected, setCitySelected] = useState(false);

  useEffect(() => {
    if (query.length > 2) {
      const fetchCities = async () => {
        try {
          const response = await axios.get(
            "https://nominatim.openstreetmap.org/search",
            {
              params: {
                city: query,
                format: "json",
              },
            }
          );
          console.log("Fetched sugested city data:", response.data);

          setSuggestions(response.data);
        } catch (error) {
          console.error("Error fetching city data:", error);
        }
      };

      fetchCities();
    } else {
      setSuggestions([]);
    }
  }, [query]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSuggestionClick = (city) => {
    console.log("SELECTED CITY => ", city);

    setQuery(city.display_name);
    setSuggestions([]);
    // TODO: Handle city selection here after knowing what is the needed data
    onSelectCity({
      city: city.name,
      geometry: { lat: city.lat, lng: city.lon },
    });
  };

  return (
    <div className={styles.locationSearch}>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="Search for a city..."
      />
      {suggestions.length > 0 && (
        <ul className={styles.suggestions}>
          {suggestions.map((city, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(city)}
              style={{ padding: "8px", cursor: "pointer" }}
              className={styles.suggestion}
            >
              {city.display_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CitySearchInput;
