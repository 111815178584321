import {
  getLanguageArray,
  getShowingLanguage,
  maskString,
} from "../../../utils/CommonUtils";
import styles from "./ClaimDetails.module.scss";
import { useLanguage } from "../../../context/useLanguage";
import { useModal } from "../../../context/useModal";
import CloseButton from "../../../components/CloseButton/CloseButton";
import { useNavigate } from "react-router-dom";
import StatusDetails from "./StatusDetails";
import { useQuery } from "@tanstack/react-query";
import { getClaimStatus } from "../../../utils/APIUtils";
import Spinner from "../../../components/ModalContents/Spinner/Spinner";
import { transformClaimDetails } from "./transformClaimsData";

export default function ClaimDetails({ ticketId, transactionId }) {
  const {
    isLoading: claimTicketDetailsIsLoading,
    isError: claimTicketDetailsIsError,
    data: claimTicketDetailsData,
    error: claimTicketDetailsError,
  } = useQuery({
    queryKey: ["claimTicketDetails"],
    queryFn: () => getClaimStatus(transactionId, ticketId),
  });
  const { language } = useLanguage();
  const navigate = useNavigate();

  const { closeModal } = useModal();

  // const queryClient = useQueryClient();

  const handleGoToClaims = () => {
    navigate("/account-settings/claims");
    closeModal();
  };

  if (claimTicketDetailsIsLoading) {
    return <Spinner />;
  }

  if (claimTicketDetailsIsError) {
    console.log("claimTicketDetailsError => ", claimTicketDetailsError);
    return <>Error</>;
  }

  const {
    status,
    statusText,
    performanceName,
    sectionName,
    typeName,
    eventName,
    showingTicketId,
    requestDate,
  } = transformClaimDetails(claimTicketDetailsData, ticketId);

  const languageArray = getLanguageArray(eventName);
  const showingLanguage = getShowingLanguage(languageArray, language);

  // Create a class name based on the status for the status tag
  const statusTagClass = `${styles.refundStatusTag} ${styles[status]}`;

  return (
    !claimTicketDetailsIsLoading &&
    !claimTicketDetailsIsError && (
      <div className={styles.claimDetails}>
        <div className={styles.claimDetailsContainer}>
          <section className={styles.claimDetailsHeader}>
            <div className={styles.claimDetailsHeaderLine}>
              <h2>Claim Details</h2>
              <div className={styles.closeButtonContainer}>
                <CloseButton onClick={closeModal} variant="noBG" />
              </div>
            </div>
            <button onClick={handleGoToClaims} className={styles.seeClaimsBtn}>
              See All Claims
            </button>
          </section>
          <section className={styles.details}>
            <div className={styles.detailsHeader}>
              <span className={styles.eventLabel}>Event</span>
              <span className={styles.eventName}>
                {eventName[showingLanguage]}
              </span>
            </div>
            <div className={styles.detailsTicket}>
              <div className={styles.detailsTicketLine}>
                <span
                  className={styles.ticketId}
                >{`Ticket #${showingTicketId}`}</span>
                <span className={statusTagClass}>{statusText}</span>
              </div>
              {/* <div className={styles.orderContainer}>
                <span className={styles.orderLabel}>Order ID</span>
                <span className={styles.orderId}>{transactionId}</span>
              </div> */}
            </div>
            <div className={styles.detailsPerformance}>
              <div className={styles.detailColumn}>
                <span className={styles.label}>Order Id</span>
                <span className={styles.data}>{transactionId}</span>
              </div>
              <div className={styles.detailColumn}>
                <span className={styles.label}>Event</span>
                <span className={styles.data}>
                  {eventName[showingLanguage]}
                </span>
              </div>
              <div className={styles.detailColumn}>
                <span className={styles.label}>Request Date</span>
                <span className={styles.data}>{requestDate}</span>
              </div>
            </div>
          </section>
          <section className={styles.statusContainer}>
            <h3>Status</h3>
            <StatusDetails
              claimData={claimTicketDetailsData}
              showingLanguage={showingLanguage}
              transactionId={transactionId}
              ticketId={ticketId}
            />
          </section>
        </div>
      </div>
    )
  );
}
