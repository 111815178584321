import styles from "./ResponseMessage.module.scss";
import CloseButton from "../../CloseButton/CloseButton";
import { useModal } from "../../../context/useModal";

export default function ResponseMessage({
  icon,
  title,
  msg,
  onClose,
  isSuccess,
  isWarning,
  notModal,
}) {
  const { closeModal } = useModal();
  let iconClass = styles.iconContainer;
  if (isSuccess) {
    iconClass = `${styles.iconContainer} ${styles.success}`;
  } else if (isWarning) {
    iconClass = `${styles.iconContainer} ${styles.warning}`;
  }

  const handleClosePress = () => {
    if (onClose) {
      onClose();
    } else {
      closeModal();
    }
  };
  return (
    <div className={styles.responseMsg}>
      {!notModal && (
        <div className={styles.btnContainer}>
          <CloseButton onClick={handleClosePress} />
        </div>
      )}
      {icon && <div className={iconClass}>{icon}</div>}
      <h2>{title}</h2>
      <p className={styles.msg}>{msg}</p>
    </div>
  );
}
