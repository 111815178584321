import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LoginInfo.module.scss";
import FormInput from "../../../components/FormInput/FormInput";
import { useAuth } from "../../../context/useAuth";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useModal } from "../../../context/useModal";
import ChangeCredentialsPopupContent from "../../../components/ChangeCredentialsPopupContent/ChangeCredentialsPopupContent";
import {
  validateEmail,
  validatePassword,
} from "../../../utils/authValidationUtils";
import blueCheck from "../../../assets/blue-check.svg";
import { updateLoginInfo, logoutUser } from "../../../utils/APIUtils";
import LoginInfoSavedModal from "../../../components/LoginInfoSaved/LoginInfoSavedModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Loading from "../../../components/Loading/Loading";
import { removeItem } from "../../../utils/LocalStorageUtils";
/**
 * LoginInfo is a functional component that renders the login information for the user to update password and email.
 *
 * @return {JSX.Element} The LoginInfo component that renders the login information form.
 */
export default function LoginInfo() {
  const auth = useAuth();
  const email = auth.user?.email || "";
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const [credentialsValidated, setCredentialsValidated] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [newEmail, setNewEmail] = useState(email);
  const [errors, setErrors] = useState({});
  const [sucess, setSuccess] = useState(false);
  let popupMsg = "Login information updated successfully. Please login again.";

  const queryClient = useQueryClient();

  const {
    mutateAsync: changeLoginInfo,
    isPending: isChangeLoginInfoPending,
    error: changeLoginInfoError,
    isError: isChangeLoginInfoError,
  } = useMutation({
    mutationFn: async (data) => updateLoginInfo(data),
    onSuccess: (response) => {
      console.log("⏎ response onSuccess in loginInfo:  ", response);
      if (!response.success) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            email:
              response.message ||
              "Error updating login info. Please try again later.",
          };
        });
      } else {
        // Invalidate the user details query to fetch the updated data
        // Also remove the session expiration date from local storage
        queryClient.invalidateQueries({ queryKey: ["userDetails"] });
        removeItem("sessionExpirationDate");
        console.log("Contact Info Updated Successfully");
        showUpdateSuccessModal(popupMsg);
        setTimeout(() => {
          closeModal();
          navigate("/account-settings/my-orders");
        }, 3000);
      }
    },
    onError: (error) => {
      console.error("Error updating login info: ", error);
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          email: "Error updating login info. Please try again later.",
        };
      });
    },
  });

  // Enable form inputs only if credentials are validated
  const isInputDisabled = !credentialsValidated;
  const showCredentialsModal = () => {
    openModal(
      <ChangeCredentialsPopupContent
        onCredentialsValidation={setCredentialsValidated}
      />,
      "white"
    );
  };

  const showUpdateSuccessModal = (message) => {
    openModal(<LoginInfoSavedModal message={message} />, "loginInfoSaved");
  };

  useEffect(() => {
    if (!auth.user) {
      navigate("/login");
    }
  }, [auth.user]);

  useEffect(() => {
    if (sucess) {
      setCredentialsValidated(false);
      setNewPassword("");
      setNewPasswordConfirm("");
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
  }, [sucess]);

  const submitChanges = async () => {
    console.log("newEmail", newEmail);
    const emailValidationError = validateEmail(newEmail);
    if (emailValidationError) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          email: emailValidationError,
        };
      });
    }
    const passwordValidationErrors = validatePassword(newPassword);
    if (Object.keys(passwordValidationErrors).length > 0) {
      if (passwordValidationErrors.password) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            ...passwordValidationErrors,
          };
        });
      }
    }
    if (Object.keys(errors).length === 0) {
      // Check if the new email is different from the old one
      let requestData;

      if (newEmail !== email) {
        requestData = { email: newEmail, password: newPassword };
        popupMsg =
          "Email updated successfully. Please verify your new email to login again.";
      } else {
        requestData = { password: newPassword };
      }
      console.log("requestData: ", requestData);
      // API call
      await changeLoginInfo(requestData);

      if (isChangeLoginInfoPending) {
        return <Loading />;
      }
    }
  };

  const handleDiscardChanges = () => {
    setNewEmail("");
    setNewPassword("");
    setNewPasswordConfirm("");
    setErrors({});
    setCredentialsValidated(false);
    navigate("/account-settings/my-orders");
  };

  return (
    <div className={styles.loginInfo}>
      <div className={styles.loginInfoContainer}>
        <h2>Login Information</h2>
        <p className={styles.desc}>
          Update your password and email information anytime.
        </p>
        <div className={styles.inputContainer}>
          <FormInput
            label="Email Address"
            type="email"
            name="email"
            value={newEmail}
            labelColor="semiDark"
            isDisabled={isInputDisabled}
            onInputChange={setNewEmail}
            error={errors.email}
            onClearError={() => setErrors({})}
          />
          <FormInput
            label="Password"
            type="password"
            name="password"
            value={credentialsValidated ? newPassword : "itDoesNotMatter"}
            labelColor="semiDark"
            isDisabled={isInputDisabled}
            onInputChange={setNewPassword}
            isRequired={credentialsValidated}
            minLength={8}
            error={errors.password}
            onClearError={() => setErrors({})}
          />
          {credentialsValidated && (
            <FormInput
              label="Confirm New Password"
              type="password"
              name="confirmPassword"
              value={newPasswordConfirm}
              labelColor="semiDark"
              onInputChange={setNewPasswordConfirm}
              isRequired={true}
              minLength={8}
              error={errors.confirmPassword}
              onClearError={() => setErrors({})}
            />
          )}
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.btnsContainer}>
            <CustomButton
              title={credentialsValidated ? "Update" : "update login Info"}
              clickHandler={
                credentialsValidated ? submitChanges : showCredentialsModal
              }
            />

            {credentialsValidated && (
              <CustomButton
                title="Discard"
                colorScheme="outlineWhite"
                clickHandler={handleDiscardChanges}
              />
            )}
          </div>
          <p className={styles.successMsg}>
            <img src={blueCheck} alt="success" />
            Your password has been updated.
          </p>
        </div>
      </div>

      <div className={styles.divider}></div>
    </div>
  );
}
