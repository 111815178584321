import styles from "./CartData.module.scss";
import { useCart } from "../../context/useCart";
import { useAuth } from "../../context/useAuth";
import {
  groupByPerformances,
  groupByEvents,
  getEventNames,
  getEventsTotal,
  assembleCheckoutObject,
} from "./CartDataUtils";
import { useNavigate } from "react-router-dom";
import {
  formatNumberToCurrency,
  getShowingLanguage,
} from "../../utils/CommonUtils";
import { useLanguage } from "../../context/useLanguage";
import CartCard from "./CartCard";
import CustomButton from "../../components/CustomButton/CustomButton";
import { getItem } from "../../utils/LocalStorageUtils";
import { useState } from "react";
import CheckoutForm from "../Checkout/ChechoutForm";
// import { deleteCart } from "../../utils/APIUtils";
// import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

export default function CartData() {
  const { cart, deleteCartMutate } = useCart();

  // const [guaranteeChecked, setGuaranteeChecked] = useState(false);
  const { user } = useAuth();
  const { language } = useLanguage();
  const [placeOrder, setPlaceOrder] = useState(false);
  const [checkoutObj, setCheckoutObj] = useState({});
  const navigate = useNavigate();
  const ticketItems = cart.ticketItems;
  const groupedByEvents = groupByEvents(ticketItems);
  const eventTotals = getEventsTotal(groupedByEvents, false);
  const eventNames = getEventNames(groupedByEvents);
  const groupedItems = groupByPerformances(groupedByEvents);

  // const promoterStripeId = getPromoterStripeId(ticketItems);
  console.log("groupedItems in CartData is => ", groupedItems);

  const handleCancelOrder = async () => {
    console.log("Cancel order");
    try {
      // Make an API call to cancel the order (all items in the cart become available and the cart is deleted)
      await deleteCartMutate();
      // Update the cart state and local storage
    } catch (err) {
      // Add error handling
      console.log(err);
    }
  };

  // TODO: Add garantee to the checkout object
  const handleCheckout = async (eventId) => {
    console.log("Checkout");
    const eventItems = groupedByEvents[eventId];
    const cartId = getItem("cartId");
    // const guaranteeAmount = guaranteeChecked
    //   ? eventTotals[eventId].garanteeAmount
    //   : 0;

    const showingLanguage = getShowingLanguage(
      eventItems[0].contentLanguage,
      language
    );
    console.log("user is => ", user);

    // const patronObj = {
    //   id: user.patronUserId,
    //   email: user.email,
    // };

    // Just add the garanteeChecked to the checkout object, the calculation is done in the backend
    // As we removed the guarantee option for now, we pass false to the backend
    const checkoutObject = assembleCheckoutObject(
      eventItems,
      showingLanguage,
      cartId,
      // patronObj,
      // guaranteeChecked
      false
    );

    console.log("checkoutObject is => ", checkoutObject);

    setCheckoutObj(checkoutObject);
    setPlaceOrder(true);
  };

  // const handleGaranteeChecked = () => {
  //   console.log("garanteeChecked is => ", guaranteeChecked);
  //   setGuaranteeChecked((prev) => !prev);
  // };

  // TODO: implement warnings for not enough items when we have a warning in the item
  return !placeOrder || Object.keys(checkoutObj).length === 0 ? (
    <div className={styles.cartData}>
      {Object.keys(groupedItems).map((eventId) => {
        return (
          <div key={eventId}>
            <h3>
              {eventNames[eventId][
                getShowingLanguage(
                  groupedByEvents[eventId][0].contentLanguage,
                  language
                )
              ] || ""}
            </h3>
            {Object.values(groupedItems[eventId]).map((itemsArray) => {
              return (
                <CartCard
                  performanceTicketData={itemsArray}
                  key={itemsArray[0].performanceId}
                />
              );
            })}
            <div className={styles.bottomContainer}>
              <div className={styles.totalContainer}>
                <p>
                  Subtotal for tickets:{" "}
                  {formatNumberToCurrency(eventTotals[eventId].ticketsAmount)}
                </p>
                <p>
                  Subtotal for fees:{" "}
                  {formatNumberToCurrency(eventTotals[eventId].feesAmount)}
                </p>
                {/* {guaranteeChecked && (
                  <p>
                    Subtotal for garantee:{" "}
                    {formatNumberToCurrency(
                      eventTotals[eventId].garanteeAmount
                    )}
                  </p>
                )} */}
              </div>
              {/* The guarantee is only available if the event allows it and the deadline is in the future */}
              {/* {groupedByEvents[eventId][0].allowCancelGuarantee &&
                checkIfGuaranteeDeadlineIsFuture(
                  groupedByEvents[eventId][0].allowCancelGuaranteeDeadline
                ) && (
                  <div className={styles.guaranteeContainer}>
                    <CustomCheckbox
                      onChangeChecked={handleGaranteeChecked}
                      checked={guaranteeChecked}
                      variant="filterCheckbox"
                    />
                    <span className={styles.guaranteeText}>
                      Add a guarantee to your order.
                    </span>
                    <button className={styles.guaranteeInfoButton}>
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </button>
                  </div>
                )} */}
              <div className={styles.btnsContainer}>
                <div className={styles.cancelBtnContainer}>
                  <CustomButton
                    title="Cancel Order"
                    clickHandler={handleCancelOrder}
                    variant={"white-outline"}
                  />
                </div>

                <div className={styles.checkoutBtnContainer}>
                  {user ? (
                    <CustomButton
                      title="Checkout"
                      clickHandler={() => handleCheckout(eventId)}
                      variant={"checkout"}
                    />
                  ) : (
                    <CustomButton
                      title="Login"
                      clickHandler={() => navigate("/login")}
                      variant={"checkout"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <CheckoutForm checkoutObj={checkoutObj} />
  );
}
