import styles from "../ViewTickets/ViewTickets.module.scss";
import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getTicketsStatus } from "../../utils/APIUtils";
import TicketCard from "../ViewTickets/TicketCard";
import { useLanguage } from "../../context/useLanguage";
import { getLanguageArray, getShowingLanguage } from "../../utils/CommonUtils";
import Spinner from "../../components/ModalContents/Spinner/Spinner";
import ResponseMessage from "../../components/ModalContents/ResponseMessage/ResponseMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPrint } from "@fortawesome/free-solid-svg-icons";

export default function TestTickets() {
  const navigate = useNavigate();
  const printRef = useRef(null);

  const showingLanguage = "en";
  const tickets = [
    {
      eventName: { en: "PHILL'S TESTING" },
      location: {
        addressLine1: "99 Dundas Street",
        addressLine2: "",
        city: "London",
        province: "ON",
        postalCode: "N6A 6K1",
      },
      ownedBy: "c591c016-ea71-49fb-b1a7-ff1e07fe07a1",
      performanceName: { en: "Gi" },
      sectionName: { en: "General" },
      startTime: "2025-05-31 13:00:00+00",
      ticketId: "TEST001",
      ticketStatus: "READY",
      typeName: { en: "READY" },
    },
    {
      eventName: { en: "PHILL'S TESTING" },
      location: {
        addressLine1: "99 Dundas Street",
        addressLine2: "",
        city: "London",
        province: "ON",
        postalCode: "N6A 6K1",
      },
      ownedBy: "c591c016-ea71-49fb-b1a7-ff1e07fe07a1",
      performanceName: { en: "Gi" },
      sectionName: { en: "General" },
      startTime: "2025-05-31 13:00:00+00",
      ticketId: "TEST002",
      ticketStatus: "CANCELLED",
      typeName: { en: "CANCELLED" },
    },
    {
      eventName: { en: "PHILL'S TESTING" },
      location: {
        addressLine1: "99 Dundas Street",
        addressLine2: "",
        city: "London",
        province: "ON",
        postalCode: "N6A 6K1",
      },
      ownedBy: "c591c016-ea71-49fb-b1a7-ff1e07fe07a1",
      performanceName: { en: "Gi" },
      sectionName: { en: "General" },
      startTime: "2025-05-31 13:00:00+00",
      ticketId: "TEST003",
      ticketStatus: "UNSOLD",
      typeName: { en: "UNSOLD" },
    },
    {
      eventName: { en: "PHILL'S TESTING" },
      location: {
        addressLine1: "99 Dundas Street",
        addressLine2: "",
        city: "London",
        province: "ON",
        postalCode: "N6A 6K1",
      },
      ownedBy: "c591c016-ea71-49fb-b1a7-ff1e07fe07a1",
      performanceName: { en: "Gi" },
      sectionName: { en: "General" },
      startTime: "2025-05-31 13:00:00+00",
      ticketId: "TEST004",
      ticketStatus: "CHECKED_IN",
      typeName: { en: "CHECKED_IN" },
    },
    {
      eventName: { en: "PHILL'S TESTING" },
      location: {
        addressLine1: "99 Dundas Street",
        addressLine2: "",
        city: "London",
        province: "ON",
        postalCode: "N6A 6K1",
      },
      ownedBy: "c591c016-ea71-49fb-b1a7-ff1e07fe07a1",
      performanceName: { en: "Gi" },
      sectionName: { en: "General" },
      startTime: "2025-05-31 13:00:00+00",
      ticketId: "TEST005",
      ticketStatus: "TRANSFERRED",
      typeName: { en: "TRANSFERRED" },
    },
    {
      eventName: { en: "PHILL'S TESTING" },
      location: {
        addressLine1: "99 Dundas Street",
        addressLine2: "",
        city: "London",
        province: "ON",
        postalCode: "N6A 6K1",
      },
      ownedBy: "c591c016-ea71-49fb-b1a7-ff1e07fe07a1",
      performanceName: { en: "Gi" },
      sectionName: { en: "General" },
      startTime: "2025-05-31 13:00:00+00",
      ticketId: "TEST006",
      ticketStatus: "PENDING_TRANSFER",
      typeName: { en: "PENDING_TRANSFER" },
    },
  ];

  const navigateToOrigin = () => {
    console.log("navigateToOrigin origin => ", origin);

    if (origin) {
      navigate(origin);
    } else {
      navigate(-1);
    }
  };

  const printTickets = () => {
    window.print();
  };

  return (
    <div className={styles.viewTickets}>
      <div className={styles.viewTicketsContainer}>
        <div className={styles.viewTicketsHeader}>
          <button className={styles.returnBtn} onClick={navigateToOrigin}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <h2 className={styles.viewTicketsTitle}>TEST TICKETS</h2>
          <button className={styles.printBtn} onClick={printTickets}>
            <FontAwesomeIcon icon={faPrint} />
            <span>Print Tickets</span>
          </button>
        </div>
        <ul className={styles.cardsContainer} ref={printRef}>
          {tickets.map((ticket) => (
            <TicketCard
              key={ticket.ticketId}
              ticket={ticket}
              showingLanguage={showingLanguage}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
