import styles from "./EventsFilter.module.scss";
import CustomRadioButton from "../CustomRadioButton/CustomRadioButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

export default function AdmissionTypeSection({
  setShowAdmissionType,
  showSearchRange,
  getFilterOptsClass,
  onAdmissionTypeChange,
  selectedAdmissionType,
  admissionTypes,
}) {
  return (
    <section>
      <div className={styles.sectionHeader}>
        <h3>Admission Type</h3>
        <button
          className={styles.showToggler}
          onClick={() => {
            setShowAdmissionType((prev) => !prev);
          }}
        >
          {showSearchRange ? (
            <FontAwesomeIcon icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} />
          )}
        </button>
      </div>

      <div className={getFilterOptsClass("admission-type")}>
        {admissionTypes.map((type) => (
          <CustomRadioButton
            key={type}
            label={type}
            btnValue={type}
            radioCurrentValue={selectedAdmissionType}
            onChangeChecked={onAdmissionTypeChange}
            variant="eventFilter"
          />
        ))}
      </div>
    </section>
  );
}
