import styles from "./EventsFilter.module.scss";
import CustomRadioButton from "../CustomRadioButton/CustomRadioButton";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import CitySearchInput from "../LocationSearch/CitySearchInput";
import ZipCodeSearchInput from "../LocationSearch/ZipCodeSearchInput";
import SearchRangeSection from "./SeachRangeSection";
import {
  getUserLocation,
  getRoughLocation,
} from "../../utils/LocalizationUtils";
import { useState } from "react";
import { useModal } from "../../context/useModal";
import Loading from "../../components/Loading/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretUp,
  faCaretDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { removeUnderlineFromString } from "../../utils/CommonUtils";
import DateSection from "./DateSection";
import AdmissionTypeSection from "./AdmissionTypeSection";
import FormatSection from "./FormatSection";
import CheckboxGroup from "./CheckboxGroup";
import CategorySection from "./CategorySection";
import EventTypes from "./EventTypes";

export default function EventsFilter({
  formats,
  selectedFormat,
  onFormatChange,
  categories,
  selectedCategories,
  onCategoryChange,
  admissionTypes,
  selectedAdmissionType,
  onAdmissionTypeChange,
  datesOptions,
  selectedDateOpt,
  onDateOptChange,
  dateRange,
  onDateRangeChange,
  onLocationSet,
  setShowFilter,
  eventTypes,
  selectedEventTypes,
  onEventTypeChange,
  showSearchRange,
  setShowSearchRange,
  onDistanceChange,
  distance,
  range,
}) {
  const [locationSearchOpt, setLocationSearchOpt] = useState("city");
  const [canUseMyLocation, setCanUseMyLocation] = useState(true);
  // Show section states
  // const [showSearchRange, setShowSearchRange] = useState(
  //   isSearchRangeOpen ? isSearchRangeOpen : false
  // );
  const [showDate, setShowDate] = useState(false);
  const [showAdmissionType, setShowAdmissionType] = useState(false);
  const [showFormat, setShowFormat] = useState(false);
  const [showCategory, setShowCategory] = useState(false);

  // Use Modal Context
  const { openModal, closeModal } = useModal();

  const myLocationSelectionHandler = async () => {
    setLocationSearchOpt("myLocation");
    try {
      openModal(<Loading />, "whiteFullWidth");
      const userLocation = await getUserLocation();
      closeModal();
      console.log("User location coordinates => ", userLocation);
      onLocationSet(userLocation);
    } catch (error) {
      console.error("Error getting user location:", error);
      setCanUseMyLocation(false);
    }
  };

  // Get types by category
  function getTypesByCategory(categoryName) {
    const types = eventTypes.find((item) => item.category === categoryName);
    return types ? types.types : [];
  }

  // Function to get unique types based on selected categories
  const getShowingTypes = () => {
    console.log("selectedCategories => ", selectedCategories);

    // Get all types from selected categories
    const roughTypes = selectedCategories.map((category) => {
      return getTypesByCategory(category);
    });

    // Remove duplicate types using Set and return as an array

    const flatTypes = roughTypes.flat();

    const uniqueTypes = [...new Set(flatTypes)];

    return uniqueTypes;
  };

  const showingTypes = ["All", ...getShowingTypes()];
  console.log("showingTypes => ", showingTypes);

  const showEventTypes =
    selectedCategories && !selectedCategories.includes("All");

  /* Receives the section and returns the class
     Sections can be:
     search-range
     date
     admission-type
     format
     category
  */
  const getFilterOptsClass = (section) => {
    switch (section) {
      case "search-range":
        return showSearchRange
          ? styles.filterOpts
          : `${styles.filterOpts} ${styles.hidden}`;
      case "date":
        return showDate
          ? styles.filterOpts
          : `${styles.filterOpts} ${styles.hidden}`;
      case "admission-type":
        return showAdmissionType
          ? styles.filterOpts
          : `${styles.filterOpts} ${styles.hidden}`;
      case "format":
        return showFormat
          ? styles.filterOpts
          : `${styles.filterOpts} ${styles.hidden}`;
      case "category":
        return showCategory
          ? styles.filterOpts
          : `${styles.filterOpts} ${styles.hidden}`;

      default:
        return styles.filterOpts;
    }
  };

  return (
    <div className={styles.eventsFilter}>
      <div className={styles.eventsFilterHeader}>
        <button
          className={styles.closeBtn}
          onClick={() => {
            setShowFilter(false);
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <h2 className={styles.eventsFilterTitle}>Filters</h2>
      </div>

      <SearchRangeSection
        showSearchRange={showSearchRange}
        setShowSearchRange={setShowSearchRange}
        getFilterOptsClass={getFilterOptsClass}
        onLocationSet={onLocationSet}
        setLocationSearchOpt={setLocationSearchOpt}
        locationSearchOpt={locationSearchOpt}
        myLocationSelectionHandler={myLocationSelectionHandler}
        canUseMyLocation={canUseMyLocation}
        onDistanceChange={onDistanceChange}
        distance={distance}
        range={range}
      />

      <div className={styles.divider}></div>

      <DateSection
        setShowDate={setShowDate}
        showSearchRange={showSearchRange}
        getFilterOptsClass={getFilterOptsClass}
        onDateOptChange={onDateOptChange}
        selectedDateOpt={selectedDateOpt}
        datesOptions={datesOptions}
        onDateRangeChange={onDateRangeChange}
        dateRange={dateRange}
      />

      <div className={styles.divider}></div>

      <AdmissionTypeSection
        setShowAdmissionType={setShowAdmissionType}
        showSearchRange={showSearchRange}
        getFilterOptsClass={getFilterOptsClass}
        onAdmissionTypeChange={onAdmissionTypeChange}
        selectedAdmissionType={selectedAdmissionType}
        admissionTypes={admissionTypes}
      />

      <div className={styles.divider}></div>

      <FormatSection
        setShowFormat={setShowFormat}
        showSearchRange={showSearchRange}
        getFilterOptsClass={getFilterOptsClass}
        onFormatChange={onFormatChange}
        selectedFormat={selectedFormat}
        formats={formats}
      />

      <div className={styles.divider}></div>

      {categories && (
        <CategorySection
          setShowCategory={setShowCategory}
          showSearchRange={showSearchRange}
          getFilterOptsClass={getFilterOptsClass}
          onCategoryChange={onCategoryChange}
          selectedCategories={selectedCategories}
          categories={categories}
        />
      )}

      {showEventTypes && (
        <>
          <div className={styles.divider}></div>
          <EventTypes
            showingTypes={showingTypes}
            onEventTypeChange={onEventTypeChange}
            selectedEventTypes={selectedEventTypes}
          />
        </>
      )}
    </div>
  );
}
