import styles from "./ResetPassword.module.scss";
import FormInput from "../../components/FormInput/FormInput";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useState, useEffect } from "react";
import { validatePassword } from "../../utils/authValidationUtils";
import { useSearchParams } from "react-router-dom";
import { getPasswordSession } from "../../utils/APIUtils";
import { createSupabaseClient } from "../../utils/supabaseUtils";
export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [searchParams] = useSearchParams();
  const tokenHash = searchParams.get("token_hash");
  const redirectTo = searchParams.get("redirectTo");
  const type = searchParams.get("type");

  console.log("🔎 tokenHash from query params:", tokenHash);
  console.log("🔎 redirectTo from query params:", redirectTo);
  console.log("🔎 type from query params:", type);
  const clearErrorWhenTyping = () => {
    setErrors({});
  };

  const supabase = createSupabaseClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_ANON_KEY
  );

  // Log environment variables (without exposing sensitive information)
  useEffect(() => {
    console.log("🔍 Environment check:");
    console.log(
      "🔍 REACT_APP_SUPABASE_URL exists:",
      !!process.env.REACT_APP_SUPABASE_URL
    );
    console.log(
      "🔍 REACT_APP_SUPABASE_ANON_KEY exists:",
      !!process.env.REACT_APP_SUPABASE_ANON_KEY
    );
    console.log(
      "🔍 REACT_APP_PATRON_API exists:",
      !!process.env.REACT_APP_PATRON_API
    );
  }, []);

  // fetches the session based on the URL params
  useEffect(() => {
    if (tokenHash && redirectTo && type) {
      const fetchPasswordSession = async () => {
        try {
          console.log("🔍 Attempting to fetch password session with:", {
            tokenHash,
            type,
          });
          const response = await getPasswordSession({
            tokenHash,
            type,
          });
          console.log("✅ response from getPasswordSession:", response);

          if (!response || !response.session) {
            console.error("❌ No session data in response:", response);
            return;
          }

          console.log("🔍 Setting Supabase session with:", {
            access_token: response.session.access_token,
            refresh_token: response.session.refresh_token,
          });

          const { data, error } = await supabase.auth.setSession({
            access_token: response.session.access_token,
            refresh_token: response.session.refresh_token,
          });

          if (error) {
            console.error("❌ Error setting Supabase session:", error);
          } else {
            console.log("✅ data from supabase:", data);
          }
        } catch (error) {
          console.error("❌ Error in fetchPasswordSession:", error);
        }
      };
      fetchPasswordSession();
    } else {
      console.log("🔎 tokenHash, redirectTo, type are not present");
    }
  }, [tokenHash, redirectTo, type, supabase]);

  // Handles the form submission
  const submitForm = (e) => {
    console.log("submitForm");
    e.preventDefault();
    const updatePassword = async () => {
      const validationErrors = validatePassword(password, confirmPassword);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        console.log("✅ password is valid");
        const { data, error } = await supabase.auth.updateUser({
          password,
        });

        if (error) {
          console.log("❌ error from supabase:", error);
        }

        console.log("✅ data from supabase:", data);

        // redirect to the redirectTo url
        if (redirectTo) {
          window.location.href = redirectTo;
        }
      }
    };
    updatePassword();
  };

  return (
    <div className={styles.resetPassword}>
      <div className={styles.resetPasswordCard}>
        <form onSubmit={submitForm}>
          <h2>Reset Password</h2>
          <FormInput
            ariaLabel={"password"}
            controlId={"password"}
            label="Password"
            type="password"
            placeholder="Enter your password"
            value={password}
            onInputChange={setPassword}
            error={errors.password}
            onClearError={clearErrorWhenTyping}
          />
          <FormInput
            ariaLabel={"confirmPassword"}
            controlId={"confirmPassword"}
            label={"Confirm Password"}
            type={"password"}
            placeholder={"Confirm Password"}
            value={confirmPassword}
            onInputChange={setConfirmPassword}
            minLength={8}
            error={errors.confirmPassword}
            onClearError={clearErrorWhenTyping}
          />

          <CustomButton type={"submit"} title={"Set Password"} />
        </form>
      </div>
    </div>
  );
}
