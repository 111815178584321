import styles from "./FormInput.module.scss";
import errorImg from "../../assets/error.svg";
export default function FormInput({
  ariaLabel,
  label,
  type,
  placeholder,
  maxLength,
  value,
  onInputChange,
  minLength,
  error,
  onClearError,
  labelColor,
  isRequired,
  name,
  inputChangeHandler,
  isDisabled,
  variant,
}) {
  const handleChange = (e) => {
    if (onClearError) onClearError();
    onInputChange(e.target.value);
  };

  const getLabelText = () => {
    if (isRequired) {
      return (
        <p className={styles.label}>
          {label} <span>*</span>
        </p>
      );
    } else {
      return <p className={styles.label}>{label}</p>;
    }
  };

  const getLabelClasses = () => {
    let labelClasses = "";
    if (labelColor) {
      labelClasses += styles[labelColor];
    }
    if (variant) {
      labelClasses += styles[variant];
    }

    return labelClasses;
  };

  const getInputClasses = () => {
    let inputClasses;
    if (error) {
      inputClasses += styles.error;
    }
    if (isDisabled) {
      inputClasses += styles.disabled;
    }
    return inputClasses;
  };

  // Label color implemented is semiDark. By default it is white.
  return (
    <label aria-label={ariaLabel} className={getLabelClasses()}>
      {getLabelText()}
      <input
        type={type || "text"}
        placeholder={placeholder}
        maxLength={maxLength ? maxLength : "150"}
        value={value}
        onChange={inputChangeHandler ? inputChangeHandler : handleChange}
        minLength={minLength}
        className={getInputClasses()}
        name={name}
        disabled={isDisabled}
      />
      {error && (
        <p className={styles.errorMsg}>
          <img src={errorImg} alt="error" />
          {error}
        </p>
      )}
    </label>
  );
}
