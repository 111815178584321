import styles from "./EventsFilter.module.scss";
import CustomRadioButton from "../CustomRadioButton/CustomRadioButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import CitySearchInput from "../LocationSearch/CitySearchInput";
import RangeSlider from "../RangeSlider/RangeSlider";

export default function SearchRangeSection({
  showSearchRange,
  setShowSearchRange,
  getFilterOptsClass,
  onLocationSet,
  setLocationSearchOpt,
  locationSearchOpt,
  myLocationSelectionHandler,
  canUseMyLocation,
  onDistanceChange,
  distance,
  range,
}) {
  return (
    <section>
      <div className={styles.sectionHeader}>
        <h3>Search Range</h3>
        <button
          className={styles.showToggler}
          onClick={() => {
            setShowSearchRange((prev) => !prev);
          }}
        >
          {showSearchRange ? (
            <FontAwesomeIcon icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} />
          )}
        </button>
      </div>

      <div className={getFilterOptsClass("search-range")}>
        <div className={styles.locationOpts}>
          <CustomRadioButton
            label="My Location"
            variant="eventFilter"
            radioCurrentValue={locationSearchOpt}
            onChangeChecked={myLocationSelectionHandler}
            btnValue="myLocation"
            disabled={!canUseMyLocation}
          />
          <CustomRadioButton
            label="Search Location"
            variant="eventFilter"
            radioCurrentValue={locationSearchOpt}
            onChangeChecked={setLocationSearchOpt}
            btnValue="city"
          />
          {locationSearchOpt === "city" && (
            <CitySearchInput onSelectCity={onLocationSet} />
          )}
          <div className={styles.sliderContainer}>
            <p className={styles.distanceMessage}>
              Show events within <span>{distance}km</span> from location
            </p>
            <RangeSlider
              value={distance}
              min={range[0]}
              max={range[1]}
              onChange={onDistanceChange}
              step={10}
            />
            <div className={styles.sliderValues}>
              <span> {range[0]}km</span>
              <span> {range[1]}km</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
