import { useEffect } from "react";
import { useAuth } from "../../context/useAuth";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { logoutUser } from "../../utils/APIUtils";
import * as storageUtils from "../../utils/LocalStorageUtils";
import Loading from "../../components/Loading/Loading";
// import { createSupabaseClient } from "../../utils/supabaseUtils";

export default function Logout() {
  console.log("Logout called");
  const auth = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  // const supabase = createSupabaseClient(
  //   process.env.REACT_APP_SUPABASE_URL,
  //   process.env.REACT_APP_SUPABASE_ANON_KEY
  // );

  const {
    mutate: logout,
    isPending: isLogoutPending,
    error: logoutUserError,
    isError: isLogoutError,
  } = useMutation({
    mutationFn: logoutUser,
    onSuccess: () => {
      console.log("logout success");
      // Remove the expiration date from local storage
      storageUtils.removeItem("sessionExpirationDate");
      // Clear the user data from the query cache
      queryClient.setQueryData(["userDetails"], null); // Clears user data immediately
      // Invalidate the user details query to force a refetch
      queryClient.invalidateQueries(["userDetails"]); // Triggers refetch
      console.log("BEFORE NAVIGATE TO LOGIN PAGE FROM LOGOUT ROUTE");

      navigate("/login");
    },
  });

  useEffect(() => {
    // supabase.auth.signOut();
    logout();
  }, []);

  return <Loading />;
}
