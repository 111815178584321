import styles from "./EventsFilter.module.scss";
import CheckboxGroup from "./CheckboxGroup";

export default function EventTypes({
  showingTypes,
  onEventTypeChange,
  selectedEventTypes,
}) {
  return (
    <section>
      <h3>Event Types</h3>
      <div className={styles.filterOpts}>
        {showingTypes.map((type) => (
          <CheckboxGroup
            value={type}
            onChangeChecked={onEventTypeChange}
            key={type}
            selectedTypes={selectedEventTypes}
          />
        ))}
      </div>
    </section>
  );
}
