import styles from "./PromoterLandingPage.module.scss";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getPromoterLandingPageContent } from "../../utils/APIUtils";
import { transformPromoterLandingPageContent } from "./transformPromoterLandingPageContent";
import EventListCard from "../../components/EventListCard/EventListCard";
import { getSocialMediaIcon, getFetchStatusIcon } from "../../utils/iconUtils";
import { getSocialMediaLink } from "../../utils/CommonUtils";
import ResponseMessage from "../../components/ModalContents/ResponseMessage/ResponseMessage";
import Loading from "../../components/Loading/Loading";
import { use } from "react";
import InitialsAvatar from "../../components/InitialsAvatar/InitialsAvatar";

export default function PromoterLandingPage({ subdomain }) {
  // console.log("subdomain in PromoterLandingPage: ", subdomain);
  const location = useLocation();
  const { pathname } = location;
  console.log("location in PromoterLandingPage: ", location);
  let queryObj;
  if (pathname.startsWith("/promoter/")) {
    // Handle pulling the data using the promoterId
    // Get current path
    // Extract the promoterId from the path
    const promoterId = pathname.split("/")[2];
    console.log("Promoter ID:", promoterId); // Example output: 123
    queryObj = {
      identifier: promoterId,
      type: "id",
    };
  } else {
    // Handle pulling the data using the subdomain
    console.log("pathname in if statement in PromoterLandingPage: ", pathname);
    const promoterCustomPath = pathname.replace(/^\//, ""); // Removes only the leading '/';
    queryObj = {
      identifier: promoterCustomPath,
      type: "path",
    };
  }

  const {
    isLoading: promoterLandingPageContentLoading,
    error: promoterLandingPageContentError,
    data: promoterLandingPageContent,
  } = useQuery({
    queryKey: ["promoterLandingPageContent"],
    queryFn: () =>
      getPromoterLandingPageContent(queryObj.identifier, queryObj.type),
  });

  if (promoterLandingPageContentLoading) {
    return <Loading />;
  }

  if (promoterLandingPageContentError) {
    return (
      <div className={styles.whiteScreen}>
        <ResponseMessage
          notModal={true}
          title="Error"
          msg="Could not find the promoter landing page."
          icon={getFetchStatusIcon("error")}
        />
      </div>
    );
  }

  console.log("promoterLandingPageContent: ", promoterLandingPageContent);

  const {
    bannerURL,
    description,
    name,
    profilePicURL,
    socialMedia,
    supportEmail,
    numberOfEventsStr,
    events,
    error,
  } = transformPromoterLandingPageContent(promoterLandingPageContent);

  if (error) {
    return (
      <div className={styles.whiteScreen}>
        <ResponseMessage
          notModal={true}
          title="Error"
          msg="Could not find the promoter landing page."
          icon={getFetchStatusIcon("error")}
        />
      </div>
    );
  }

  return (
    <div className={styles.promoterLandingPage}>
      <div className={styles.promoterLandingPageContainer}>
        <div className={styles.bannerContainer}>
          <img src={bannerURL} alt="Banner" className={styles.banner} />
        </div>
        <div className={styles.contentWrapper}>
          <section className={styles.promoterDetailsContainer}>
            <div className={styles.promoterProfilePicContainer}>
              {profilePicURL ? (
                <img
                  src={profilePicURL}
                  alt="Profile Pic"
                  className={styles.profilePic}
                />
              ) : (
                <InitialsAvatar name={name} />
              )}
            </div>
            <div className={styles.promoterDetails}>
              <div className={styles.details}>
                <span className={styles.promoterName}>{name}</span>
                <span className={styles.promoterEmail}>{supportEmail}</span>
                <span className={styles.numberOfEvents}>
                  {numberOfEventsStr}
                </span>
              </div>
              <ul className={styles.socialMedia}>
                {socialMedia.map((socialMediaItem, index) => (
                  <li
                    key={`${socialMediaItem.id}-${index}`}
                    className={styles.socialMediaItem}
                  >
                    <a
                      href={getSocialMediaLink(
                        socialMediaItem.platform,
                        socialMediaItem.accountId
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.socialMediaLink}
                    >
                      {getSocialMediaIcon(socialMediaItem.platform)}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <section className={styles.promoterDescriptionContainer}>
            <h2>About Me</h2>
            <p className={styles.promoterDescription}>{description}</p>
          </section>
          <section className={styles.eventsContainer}>
            <h2>Events:</h2>
            <ul className={styles.eventsList}>
              {events.map((event) => (
                <EventListCard key={event.id} event={event} />
              ))}
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}
