import styles from "./EventsFilter.module.scss";
import CustomRadioButton from "../CustomRadioButton/CustomRadioButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import CustomCalendar from "../CustomCalendar/CustomCalendar";

export default function DateSection({
  setShowDate,
  showSearchRange,
  getFilterOptsClass,
  onDateOptChange,
  selectedDateOpt,
  datesOptions,
  dateRange,
  onDateRangeChange,
}) {
  const handleInputChange = (event, isStart) => {
    const newDate = event.target.value ? new Date(event.target.value) : null;

    if (newDate) {
      newDate.setHours(
        isStart ? 0 : 23,
        isStart ? 0 : 59,
        isStart ? 0 : 59,
        999
      );
    }

    onDateRangeChange((prev) =>
      isStart ? [newDate, prev[1]] : [prev[0], newDate]
    );
  };
  return (
    <section>
      <div className={styles.sectionHeader}>
        <h3>Date</h3>
        <button
          className={styles.showToggler}
          onClick={() => {
            setShowDate((prev) => !prev);
          }}
        >
          {showSearchRange ? (
            <FontAwesomeIcon icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} />
          )}
        </button>
      </div>
      <div className={getFilterOptsClass("date")}>
        {datesOptions.map((type) => (
          <CustomRadioButton
            key={type}
            label={type}
            btnValue={type}
            radioCurrentValue={selectedDateOpt}
            onChangeChecked={onDateOptChange}
            variant="eventFilter"
          />
        ))}
        {selectedDateOpt === "Date Range" && (
          <CustomCalendar
            value={dateRange}
            setValue={onDateRangeChange}
            isRangeMode={true}
          />
        )}
      </div>
    </section>
  );
}
