import React, { useEffect, createContext, useContext, useMemo } from "react";
import { addToCart, deleteCart } from "../utils/APIUtils";
import { setItem, getItem } from "../utils/LocalStorageUtils";
import { clearCartLocalStorage } from "../utils/CommonUtils";
import {
  getCartDataByCartId,
  removeItemsFromCart,
  addPatronIdToCart,
  getCartDataByPatronId,
  // checkoutHandler,
} from "../utils/APIUtils";
import { useAuth } from "../context/useAuth";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
export const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const { user } = useAuth();
  const cartExpiration = getItem("cartExpiration");

  const fetchCartData = async () => {
    const cartId = getItem("cartId") || null;
    if (cartId) {
      console.log("getCartData called with cartId => ", cartId);
      const cartData = await getCartDataByCartId();
      return cartData;
    }
    if (user && user.patronUserId) {
      console.log(
        "getCartDataByPatronId called with patronUserId => ",
        user.patronUserId
      );

      const cartData = await getCartDataByPatronId(user.patronUserId);
      return cartData;
    }
    console.log("No cartId or patronUserId found");
    return null;
  };

  const {
    status,
    data: cart,
    error,
    isFetching,
  } = useQuery({
    queryKey: ["cartData"],
    queryFn: fetchCartData,
  });

  const queryClient = useQueryClient(); // Access the query client instance

  console.log("🛒 cart in useCart is => ", cart);

  // Handle cart changes function
  const handleCartChanges = async () => {};

  useEffect(() => {
    const handleUserChange = async () => {
      const cartId = getItem("cartId") || null;
      if (
        (!cart || Object.keys(cart).length === 0) &&
        user &&
        user.patronUserId
      ) {
        // Get cart data using patronUserId
        changeUserMutate(user.patronUserId);
      }
      if (!cart) return;
      if (Object.keys(cart).length > 0 && user && user.patronUserId && cartId) {
        // TODO: Check if there is a cart and if the cart has a patronUserId
        const addedPatronIdToCartResponse = addPatronToCartMutate({
          patronId: user.patronUserId,
          cartId,
        });
        console.log(
          "addedPatronIdToCartResponse is => ",
          addedPatronIdToCartResponse
        );
      } else {
        if (Object.keys(cart).length === 0 && user && user.patronUserId) {
          // Get cart data using patronUserId
          console.log("user.patronUserId is => ", user.patronUserId);

          const cart = await getCartDataByPatronId(user.patronUserId);
          console.log("cart IN HANDLE USER CHANGE IS => ", cart);

          if (
            cart.message === "CART_NOT_FOUND" ||
            cart.message === "CART_EXPIRED"
          ) {
            console.log("cart.message is => ", cart.message);

            return;
          }
        }
      }
    };
    handleUserChange();
  }, [user]);

  // Clear cart data from local storage if there is no cart, cart is expired, or cart has no tickets
  useEffect(() => {
    const cartId = getItem("cartId") || null;
    console.log("🛒 cart in handleCartChanges useEffect is => ", cart);
    console.log("🛒 cartId in handleCartChanges useEffect is => ", cartId);

    if (cart && cart.cartId) {
      if (!cart.ticketItems || cart.ticketItems.length === 0) {
        // Clear cart data from local storage
        deleteCartMutate();
        clearCartLocalStorage();
      } else {
        setItem("cartId", cart.cartId);
      }
    }
    if (!cart) {
      // Clear cart data from local storage
      clearCartLocalStorage();
    }
    if (
      cart &&
      (cart.message === "CART_EXPIRED" || cart.message === "CART_NOT_FOUND")
    ) {
      // Clear cart data from local storage
      clearCartLocalStorage();
    }

    if (cart && cart.ticketItems && cart.ticketItems.length === 0) {
      clearCartLocalStorage();
    }

    // Add patronUserId to cart
    if (
      cart &&
      cart.ticketItems &&
      cart.ticketItems.length > 0 &&
      user &&
      user.patronUserId &&
      !cart.patronUserId
    ) {
      const addedPatronIdToCartResponse = addPatronToCartMutate({
        patronId: user.patronUserId,
        cartId,
      });
      console.log(
        "addedPatronIdToCartResponse is => ",
        addedPatronIdToCartResponse
      );
    }

    // Update cart expiration time
    if (cart && cart.expiredAt && cart.length > 0) {
      const newDate = new Date(cart.expiredAt).getTime();
      setItem("cartExpiration", newDate);
    }
  }, [cart]);

  //use reduce to calculate the number of tickets
  const getTicketCount = (cart) => {
    let ticketCount = 0;
    console.log("cart in getTicketCount is => ", cart);

    if (!cart || !cart.ticketItems || Object.keys(cart).length === 0) return 0;
    const typeCount = cart.ticketItems.reduce((tickets, ticket) => {
      if (ticket) {
        return tickets + ticket.selectedQuantity;
      }
      return tickets;
    }, 0);
    console.log("typeCount is => ", typeCount);

    ticketCount += typeCount;
    return ticketCount;
  };

  const cartCount = useMemo(() => getTicketCount(cart), [cart]);

  console.log("cartCount is => ", cartCount);
  console.log("cart is => ", cart);

  // Handles the add to cart action
  const handleAddToCart = async (items) => {
    const cartId = getItem("cartId") || null;
    console.log("handleAddToCart called to add items => ", items);
    if (items.length === 0) return;
    const requestData = {
      items: items,
    };
    // Check if there is a cartId and add it to the request if so
    if (cartId) {
      requestData.cartId = cartId;
    }

    const addToCartResponse = await addToCart(requestData);
    console.log("addToCartResponse is => ", addToCartResponse);

    // Check and update the cart data
    // Add the cart data to local storage
    if (addToCartResponse.cartId && addToCartResponse.expiredAt) {
      console.log("addToCartResponse ****** is => ", addToCartResponse);

      setItem("cartId", addToCartResponse.cartId);
      setItem(
        "cartExpiration",
        new Date(addToCartResponse.expiredAt).getTime()
      );
      return true;
    } else {
      console.error(
        "❌ Error in HandleAddToCart: addToCartResponse is => ",
        addToCartResponse
      );
      // TODO: HANDLE ERROR
    }
  };

  // Handles editing cart items quantity from the cart page
  const handleEditCartItems = async (typeId, newQuantity) => {
    const cartId = getItem("cartId") || null;
    // Compare the previous quantity with the new quantity
    const previousQty = cart.ticketItems.find(
      (item) => item.typeId === typeId
    ).selectedQuantity;
    const difference = newQuantity - previousQty;
    if (difference === 0) return;
    // Get the object from the cart that has the same typeId
    const typeItem = cart.ticketItems.find((item) => item.typeId === typeId);
    if (previousQty > newQuantity) {
      // Get an array with the items to be removed (array of ticketIds)
      const removedItems = typeItem.ticketsIds.splice(0, Math.abs(difference));
      const requestData = {
        cartId: cartId,
        removedItems: removedItems,
      };
      // Make the API call to remove the items
      try {
        removeMutate(requestData);
        // await removeItemsFromCart(requestData);
        return true;
      } catch (error) {
        console.log("error on removeItemsFromCart: ", error);
      }
    }

    if (previousQty < newQuantity) {
      console.log("INCREASE QUANTITY FOR TYPE => ", typeItem);
      const itemWithNewQuantity = {
        performanceId: typeItem.performanceId,
        sectionId: typeItem.sectionId,
        typeId: typeItem.typeId,
        selectedQuantity: difference,
      };
      addMutate([itemWithNewQuantity]);
    }
  };

  // Multations
  const {
    mutate: addMutate,
    isPending: addIsPending,
    error: addMutateError,
    isError: addIsError,
  } = useMutation({
    mutationFn: handleAddToCart,
    onSuccess: (data) => {
      console.log(
        "➕ Item added to cart successfully. Fetching updated cart data..."
      );
      queryClient.setQueryData({ queryKey: ["cartData"] }, null);
      queryClient.invalidateQueries({ queryKey: ["cartData"] });
    },
  });

  const {
    mutate: removeMutate,
    isPending: removeIsPending,
    error: removeMutateError,
    isError: removeIsError,
  } = useMutation({
    mutationFn: removeItemsFromCart,
    onSuccess: (data) => {
      console.log("Cart edited successfully. Fetching updated cart data...");
      queryClient.invalidateQueries({ queryKey: ["cartData"] });
    },
  });

  const {
    mutate: addPatronToCartMutate,
    isPending: addPatronToCartIsPending,
    error: addPatronToCartMutateError,
    isError: addPatronToCartIsError,
  } = useMutation({
    mutationFn: addPatronIdToCart,
    onSuccess: (data) => {
      console.log(
        "Patron added to Cart successfully. Fetching updated cart data..."
      );
      queryClient.invalidateQueries({ queryKey: ["cartData"] });
    },
  });

  const {
    mutate: deleteCartMutate,
    isPending: deleteCartIsPending,
    error: deleteCartError,
    isError: deleteCartIsError,
  } = useMutation({
    mutationFn: deleteCart,

    onSuccess: (data) => {
      console.log("Cart deleted successfully. Fetching updated cart data...");
      queryClient.invalidateQueries({ queryKey: ["cartData"] });
    },
  });

  const {
    mutate: changeUserMutate,
    isPending: changeUserIsPending,
    error: changeUserMutateError,
    isError: changeUserIsError,
  } = useMutation({
    mutationFn: getCartDataByPatronId,

    onSuccess: (data) => {
      console.log("Data on changeUserMutate is => ", data);

      console.log(
        "Patron cart suscessfully fetched. Fetching updated cart data..."
      );
      queryClient.invalidateQueries({ queryKey: ["cartData"] });
    },
  });

  return (
    <CartContext.Provider
      value={{
        cart,
        addMutate,
        cartCount,
        cartExpiration,
        handleEditCartItems,
        deleteCartMutate,
        fetchCartData,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
