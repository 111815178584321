import styled from "styled-components";
import FilterChip from "../../components/FilterChip/FilterChip";
import styles from "./ChipsContainer.module.scss";
export default function ChipsContainer({
  dateType,
  dateRange,
  setDateType,
  admissionType,
  setAdmissionType,
  format,
  setFormat,
  categories,
  onCategoriesChange,
  eventTypes,
  onEventTypesChange,
}) {
  const showingDate =
    dateType && dateType === "All"
      ? null
      : dateType === "dateRange"
      ? dateRange
      : dateType;
  const showingAdmissionType =
    admissionType && admissionType === "All" ? null : admissionType;
  const showingFormat = format && format === "All" ? null : format;
  const showingCategories =
    categories && categories[0] === "All" ? null : categories;
  const showingEventTypes =
    eventTypes && eventTypes[0] === "All" ? null : eventTypes;
  return (
    <ul className={styles.chipsContainer}>
      {showingDate && (
        <FilterChip
          label={showingDate}
          btnHandler={() => {
            setDateType("All");
          }}
        />
      )}
      {showingAdmissionType && (
        <FilterChip
          label={showingAdmissionType}
          btnHandler={() => {
            setAdmissionType("All");
          }}
        />
      )}
      {showingFormat && (
        <FilterChip
          label={showingFormat}
          btnHandler={() => {
            setFormat("All");
          }}
        />
      )}
      {showingCategories &&
        showingCategories.map((category) => (
          <FilterChip
            key={category}
            label={category}
            btnHandler={() => {
              onCategoriesChange(category);
            }}
          />
        ))}
      {showingEventTypes &&
        showingEventTypes.map((eventType) => (
          <FilterChip
            key={eventType}
            label={eventType}
            btnHandler={() => {
              onEventTypesChange(eventType);
            }}
          />
        ))}
    </ul>
  );
}
