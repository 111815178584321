import { useState } from "react";
import styles from "./EventDashboard.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export default function SearchInputText({ onSetInputText, inputInitialValue }) {
  const [searchText, setSearchText] = useState(inputInitialValue || "");

  const handleButtonClick = () => {
    onSetInputText(searchText);
  };

  const handleInputBlur = () => {
    if (searchText === "") {
      onSetInputText("");
    }
  };
  return (
    <>
      <input
        type="text"
        placeholder="Find Events"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onBlur={handleInputBlur}
      />
      <button onClick={handleButtonClick}>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </button>
    </>
  );
}
