import { useState, useEffect } from "react";
import { useDebounce } from "../../hooks/useDebounce";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import styles from "./RangeSlider.module.scss";

export default function RangeSliderComponent({
  value,
  min,
  max,
  onChange,
  step = 1,
}) {
  const [innerValue, setInnerValue] = useState(Number(value));
  console.log("innerValue type => ", typeof innerValue);

  const handleChange = (value) => {
    console.log("newValue => ", value[1]);

    setInnerValue(Number(value[1]));
  };

  const handleDragEnd = () => {
    onChange(innerValue);
  };

  return (
    <div className={styles.rangeSlider}>
      <RangeSlider
        className={styles.slider}
        min={min}
        max={max}
        step={step}
        value={[10, innerValue]}
        thumbsDisabled={[true, false]}
        rangeSlideDisabled={true}
        onInput={handleChange}
        onThumbDragEnd={handleDragEnd}
      />
    </div>
  );
}
