import styles from "./EventsFilter.module.scss";
import CustomRadioButton from "../CustomRadioButton/CustomRadioButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

export default function FormatSection({
  setShowFormat,
  showSearchRange,
  getFilterOptsClass,
  onFormatChange,
  selectedFormat,
  formats,
}) {
  return (
    <section>
      <div className={styles.sectionHeader}>
        <h3>Format</h3>
        <button
          className={styles.showToggler}
          onClick={() => {
            setShowFormat((prev) => !prev);
          }}
        >
          {showSearchRange ? (
            <FontAwesomeIcon icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} />
          )}
        </button>
      </div>
      <div className={getFilterOptsClass("format")}>
        {formats.map((format) => (
          <CustomRadioButton
            key={format}
            label={format}
            btnValue={format}
            radioCurrentValue={selectedFormat}
            onChangeChecked={onFormatChange}
            variant="eventFilter"
          />
        ))}
      </div>
    </section>
  );
}
