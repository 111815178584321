import { createClient } from "@supabase/supabase-js";

export const sessionStorageAdapter = {
  getItem: (key) => {
    return sessionStorage.getItem(key);
  },
  setItem: (key, value) => {
    sessionStorage.setItem(key, value);
  },
  removeItem: (key) => {
    sessionStorage.removeItem(key);
  },
};

export const createSupabaseClient = (supabaseURL, supabaseKey) => {
  console.log("createSupabaseClient called");

  return createClient(supabaseURL, supabaseKey, {
    auth: {
      //   detectSessionInUrl: true,
      detectSessionInUrl: true,
      flowType: "pkce",
      storage: sessionStorageAdapter,
    },
  });
};

export const setSession = async (supabase, accessToken, refreshToken) => {
  await supabase.auth.setSession({
    access_token: accessToken,
    refresh_token: refreshToken,
  });
};

export const getSession = async (supabase) => {
  return await supabase.auth.getSession();
};
