import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { register } from "../../utils/APIUtils";
import FormInput from "../../components/FormInput/FormInput";
import ConfirmVerificationCode from "../../components/ConfirmVerificationCode/ConfirmVerificationCode";
import AuthenticationHead from "../../components/AuthenticationHead/AuthenticationHead";
import AuthenticationFoot from "../../components/AuthenticationFoot/AuthenticationFoot";
import styles from "./SignUp.module.scss";
import { validate } from "../../utils/authValidationUtils";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import { useModal } from "../../context/useModal";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy";
import { useMutation } from "@tanstack/react-query";

export default function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [rememberUser, setRememberUser] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const modal = useModal();
  const { openModal } = modal;

  const {
    mutateAsync: signUp,
    isPending: isSignUpPending,
    error: signUpError,
    isError: isSignUpError,
  } = useMutation({
    mutationFn: (registerData) => register(registerData),
    onError: (err) => {
      return <>Error</>;
    },

    onSuccess: (response) => {
      console.log("Register Successful! Response => ", response);
      handleRegisterSuccess(response);
    },
  });

  /**
   * Resets all the fields in the form.
   *
   * @return {void} This function does not return anything.
   */
  const resetAllFields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  /**
   * Handles the success of user registration.
   *
   * @param {Object} res - The response object containing user data.
   * @return {void}
   */
  const handleRegisterSuccess = (res) => {
    console.log("🔙 handleRegisterSuccess response => ", res);
    // Check if the response contains code VERIFY_EMAIL
    if (res.data.code === "VERIFY_EMAIL") {
      console.log("✅ CODE VERIFY_EMAIL");
      // Navigate to login page sending the verify email code
      navigate("/login?verified=false");
    } else {
      // Navigate to login page
      navigate("/login");
    }
  };

  /**
   * Handles the error of user registration.
   *
   * @param {Object} err - The error object containing the error message.
   * @return {void}
   */
  const handleRegisterError = (err) => {
    console.log("🔙 handleRegisterError err => ", err);
    let message = "Something went wrong please try again later";

    // Check if the error response contains an error message
    // Using the generateError function in the API it will return an error object
    // with a message property
    if (err.response?.data?.error?.message) {
      message = err.response.data.error.message;
    }

    setErrors({ auth: message });
  };

  /**
   * Handles the form submission event.
   *
   * @param {Event} e - The form submission event.
   * @return {void} This function does not return anything.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO:Implement captcha when ready to use
    const errs = validate({
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    });
    if (Object.keys(errs).length === 0) {
      const registerData = {
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
        userType: "member",
      };
      signUp(registerData)
    } else {
      setErrors(errs);
    }
  };

  const clearErrorWhenTyping = () => {
    setErrors({});
  };

  const toggleRememberUser = () => {
    console.log("rememberUser in toggle: ", rememberUser);
    setRememberUser(!rememberUser);
  };
  const toggleAgreed = () => {
    setAgreed(!agreed);
  };

  return (
    <div className={styles.signUp}>
      <div className={styles.signUpCard}>
        <AuthenticationHead isLogin={false} />
        <form onSubmit={handleSubmit}>
          <FormInput
            ariaLabel="first name"
            label="First name"
            type="text"
            value={firstName}
            onInputChange={setFirstName}
            error={errors.firstName}
            onClearError={clearErrorWhenTyping}
          />
          <FormInput
            ariaLabel="last name"
            label="Last name"
            type="text"
            value={lastName}
            onInputChange={setLastName}
            error={errors.lastName}
            onClearError={clearErrorWhenTyping}
          />
          <FormInput
            ariaLabel="username"
            label="Email"
            type="email"
            value={email}
            onInputChange={setEmail}
            error={errors.email}
            onClearError={clearErrorWhenTyping}
          />
          <FormInput
            ariaLabel="password"
            label="Password"
            type="password"
            value={password}
            onInputChange={setPassword}
            minLength={8}
            error={errors.password || errors.auth}
            onClearError={clearErrorWhenTyping}
          />
          <FormInput
            ariaLabel="password confirmation"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onInputChange={setConfirmPassword}
            minLength={8}
            error={errors.confirmPassword}
            onClearError={clearErrorWhenTyping}
          />
          <div className={styles.legalContainer}>
            <label className={styles.legalLabel}>
              <CustomCheckbox
                onChangeChecked={toggleRememberUser}
                checked={rememberUser}
              />
              <span className={styles.legalText}>Remember me</span>
            </label>
            <label className={styles.legalLabel}>
              <CustomCheckbox onChangeChecked={toggleAgreed} checked={agreed} />
              <span className={styles.legalText}>
                I agree to all the{" "}
                <button
                  type="button"
                  onClick={() => openModal(<TermsAndConditions />)}
                >
                  Terms
                </button>{" "}
                and{" "}
                <button
                  type="button"
                  onClick={() => openModal(<PrivacyPolicy />)}
                >
                  Privacy policy
                </button>{" "}
              </span>
            </label>
            <p className={styles.legal}>
              {" "}
              By continuing past this page, you agree to the Terms of Use and
              understand that information will be used as described in our
              Privacy Policy.
            </p>
          </div>
          <button className={styles.signUpBtn} type="submit">
            Create account
          </button>
        </form>
        <AuthenticationFoot />
      </div>
    </div>
  );
}
