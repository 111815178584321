import styles from "./EventPerformanceHeader.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faHeart,
  faShareNodes,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { formatDateToAmericanStyle } from "../../utils/CommonUtils";
import CustomButton from "../../components/CustomButton/CustomButton";
import PremiumSponsorCard from "../../components/PremiumSponsorCard/PremiumSponsorCard";
import { Link } from "react-router-dom";

const ButtonContent = ({ startingPrice }) => {
  return (
    <div className={styles.btnTitleContainer}>
      <p>Buy Tickets</p>
      <span>Start from {startingPrice}</span>
    </div>
  );
};
export default function EventPerformanceHeader({
  banner,
  title,
  startTime,
  onShareClick,
  promoterName,
  promoterRoute,
  promoterId,
  startingPrice,
  sponsors,
  onAddToFavClick,
  location,
  language,
  onTicketClick,
}) {
  // Get premium sponsor from eventDetails
  const getPremiumSponsor = () => {
    console.log("getPremiumSponsor");
    if (!sponsors) return null;
    const premiumSponsor = sponsors.find((sponsor) => sponsor.isTopSponsor);

    return premiumSponsor;
  };

  const promoterLinkClass = promoterRoute
    ? styles.promoterBtn
    : `${styles.promoterBtn} ${styles.disabled}`;

  const premiumSponsor = getPremiumSponsor();

  return (
    <div className={styles.eventPerformanceHeader}>
      {banner && (
        <div className={styles.posterContainer}>
          <img src={banner} alt="Event Poster" />
          <div className={styles.ticketBtnContainer}>
            <div className={styles.btnPos}>
              <CustomButton
                variant="eventViewTicketsHeader"
                title={<ButtonContent startingPrice={startingPrice} />}
                clickHandler={onTicketClick}
              />
            </div>
          </div>
        </div>
      )}
      <div className={styles.headerCard}>
        <div className={styles.infoContainer}>
          <div className={styles.group1}>
            <h2>{title}</h2>
            <p className={styles.date}>
              <span>
                <FontAwesomeIcon icon={faCalendarDays} />
              </span>
              {formatDateToAmericanStyle(startTime)}
            </p>
            {location && location.venue && (
              <a href="" className={styles.venue}>
                <span>
                  <FontAwesomeIcon icon={faLocationDot} />
                </span>
                {location.venue}
              </a>
            )}
            <Link className={promoterLinkClass} to={promoterRoute}>
              By
              {/* TODO: ADD PROMOTER NAME FROM API */}
              <span>{promoterName}</span>
            </Link>
          </div>
          <div className={styles.group2}>
            <div className={styles.btnsContainer}>
              <button className={styles.btn} onClick={onAddToFavClick}>
                <span>
                  <FontAwesomeIcon icon={faHeart} />
                </span>
                Add to favourite
              </button>
              <button className={styles.btn} onClick={onShareClick}>
                <span>
                  <FontAwesomeIcon icon={faShareNodes} />
                </span>
                Share
              </button>
            </div>
            {premiumSponsor && (
              <PremiumSponsorCard
                sponsorName={premiumSponsor.name}
                sponsorTag={premiumSponsor.title[language]}
                sponsorIcon={premiumSponsor.logo}
                sponsonLink={premiumSponsor.url}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
