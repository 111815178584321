import axios from "axios";

const getCityAndCountry = async (latitude, longitude) => {
  try {
    const response = await axios.get(
      "https://nominatim.openstreetmap.org/reverse",
      {
        params: {
          lat: latitude,
          lon: longitude,
          format: "json",
        },
      }
    );

    const data = response.data;
    const city = data.address.city || data.address.town || data.address.village;
    const country = data.address.country;

    console.log(`City: ${city}, Country: ${country}`);
    return { city, country };
  } catch (error) {
    console.error("Error fetching location data:", error);
    return null;
  }
};

export const getUserLocation = async () => {
  if (!navigator.geolocation) {
    throw new Error("Geolocation is not supported by this browser.");
  }

  try {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        resolve,
        (error) => reject(error) // Pass the error object directly to the reject function
      );
    });

    console.log("POSITION IN GETUSERLOCATION => ", position);
    const lat = +position.coords.latitude;
    const lng = +position.coords.longitude;

    const { city, country } = await getCityAndCountry(lat, lng);

    return {
      city,
      countryCode: country,
      geometry: {
        lat,
        lng,
      },
    };
  } catch (error) {
    // Handle different error codes
    switch (error.code) {
      case error.PERMISSION_DENIED:
        throw new Error("User denied the request for Geolocation.");
      case error.POSITION_UNAVAILABLE:
        throw new Error("Location information is unavailable.");
      case error.TIMEOUT:
        throw new Error("The request to get user location timed out.");
      default:
        throw new Error("An unknown error occurred.");
    }
  }
};

export const getRoughLocation = async () => {
  try {
    const response = await axios.get("https://get.geojs.io/v1/ip/geo.json");
    const data = response.data;
    console.log("Fetched IP location data:", data);

    console.log(
      `City: ${data.city}, Region: ${data.region}, Country: ${data.country}`
    );

    return {
      city: data.city,
      countryCode: data.country_code,
      geometry: { lat: data.latitude, lng: data.longitude },
    };
  } catch (error) {
    console.error("Error fetching IP location:", error);
  }
};
