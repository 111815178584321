import styles from "./SimilarEventsCard.module.scss";
import { transformSimilarEventsCard } from "./eventTransformData";
import {
  formatDateToFilter,
  getLanguageArray,
  getShowingLanguage,
} from "../../utils/CommonUtils";
import { useLanguage } from "../../context/useLanguage";

export default function SimilarEventsCard({ event }) {
  const {
    eventId,
    eventTitle,
    eventImage,
    eventStartTime,
    eventStartingPrice,
    eventVenue,
  } = transformSimilarEventsCard(event);
  const { language } = useLanguage();
  const languageArray = getLanguageArray(eventTitle);
  const showingLanguage = getShowingLanguage(languageArray, language);

  const imgURL = `${process.env.REACT_APP_IMG_BASE_URL}${eventImage}/public`;

  return (
    <li className={styles.similarEventsCard}>
      {/* <div> */}
      <div className={styles.imageContainer}>
        <img src={imgURL} alt={eventTitle} width={100} height={100} />
      </div>
      <div className={styles.infoContainer}>
        <h3 className={styles.title}>{eventTitle[showingLanguage]}</h3>
        <span className={styles.date}>{eventStartTime}</span>
        <span className={styles.venue}>{eventVenue}</span>
        <span className={styles.price}>{eventStartingPrice}</span>
      </div>
      <div className={styles.btnsContainer}>
        <button className={styles.shareBtn}>share</button>
        <button className={styles.detailsBtn}>details</button>
      </div>
      {/* </div> */}
    </li>
  );
}
