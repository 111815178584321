// CalendarWithInterval.jsx
import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./CustomCalendar.module.scss";
import styles from "./CustomCalendar.module.scss";

export default function CustomCalendar({ value, setValue, isRangeMode }) {
  const handleChange = (selectedValue) => {
    console.log("selectedValue => ", selectedValue);
    setValue(selectedValue);
  };
  // const [selectingEnd, setSelectingEnd] = useState(false);

  // const handleDateClick = (date) => {
  //   if (selectionMode === "single") {
  //     setStartDate(date);
  //     setEndDate(null);
  //     return;
  //   }

  //   if (!selectingEnd) {
  //     setStartDate(date);
  //     setEndDate(null);
  //     setSelectingEnd(true);
  //   } else {
  //     if (date >= startDate) {
  //       setEndDate(date);
  //       setSelectingEnd(false);
  //     } else {
  //       setStartDate(date);
  //       setEndDate(null);
  //     }
  //   }
  // };

  // // Function to determine if a date is within the interval
  // const isInInterval = (date) => {
  //   if (!startDate || !endDate) return false;
  //   return isWithinInterval(date, { start: startDate, end: endDate });
  // };

  return (
    <div className={styles.calendarWrapper}>
      <Calendar
        className={styles.customCalendar}
        onChange={handleChange}
        value={value}
        selectRange={isRangeMode} // Enable range selection dynamically
        minDetail="month"
        next2Label={null}
        prev2Label={null}
      />
    </div>
  );
}
