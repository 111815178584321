import { useEffect, useState } from "react";
import { useCart } from "../../context/useCart";
import { Link } from "react-router-dom";
import styles from "./CheckoutRedirect.module.scss";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Spinner from "../../components/ModalContents/Spinner/Spinner";
import { getOrderDetailsBySessionId } from "../../utils/APIUtils";
import { useModal } from "../../context/useModal";
// import { transformOrderData } from "../AccountSettings/MyOrders/ordersTransformation";
import { useLanguage } from "../../context/useLanguage";
import orderCompletedIcon from "../../assets/order_completed_icon.svg";
import TransferTickets from "../AccountSettings/Tickets/TransferTickets/TransferTickets";
import { getLanguageArray, getShowingLanguage } from "../../utils/CommonUtils";
import { createTicketTransfer } from "../../utils/APIUtils";
import {
  transformCheckoutData,
  transformCheckoutDataForCalendar,
} from "./transformCheckoutData";
import { handleAddToCalendar } from "../AccountSettings/Tickets/TicketsUtils";
// import ResponseMessage from "../../components/ModalContents/ResponseMessage/ResponseMessage";
export default function CheckoutRedirect() {
  const { fetchCartData } = useCart();
  const { language } = useLanguage();
  const { openModal, closeModal } = useModal();
  const [sessionId, setSessionId] = useState("");
  const queryClient = useQueryClient();
  // TODO: QUERY THE DB TO GET THE ORDER ID USING THE SESSION ID
  useEffect(() => {
    const handleRedirect = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      console.log("urlParams in CheckoutRedirect =>", urlParams);
      const sessionId = urlParams.get("session_id");
      console.log("sessionId in CheckoutRedirect =>", sessionId);
      setSessionId(sessionId);
      fetchCartData();
    };
    // Invalidate the cart data query to fetch the updated cart data
    console.log("🛒 invalidating cart data in CheckoutRedirect...");

    queryClient.invalidateQueries({ queryKey: ["cartData"] });
    handleRedirect();
  }, []);

  const {
    isLoading: orderDetailsIsLoading,
    isError: orderDetailsIsError,
    data: orderDetailsData,
    error: orderDetailsError,
  } = useQuery({
    queryKey: ["purchaseData", sessionId],
    queryFn: () => getOrderDetailsBySessionId(sessionId),
  });

  console.log("orderDetailsData in CheckoutRedirect =>", orderDetailsData);

  if (orderDetailsIsLoading) {
    return (
      <div className={styles.blackScreen}>
        <Spinner />;
      </div>
    );
  }
  if (orderDetailsIsError) {
    if (sessionId) {
      // Add the link to the orders page, so the user can see it there.
    }
  }
  const { listOfTickets, eventName } = transformCheckoutData(orderDetailsData);
  const languageArray = getLanguageArray(eventName);
  const showingLanguage = getShowingLanguage(languageArray, language);

  const handleTransferTickets = () => {
    console.log("handleTransferTickets in CheckoutRedirect =>");
    openModal(
      <TransferTickets
        ticketsList={listOfTickets}
        showingLanguage={showingLanguage}
        onCloseModal={closeModal}
        createTicketTransferMultation={createTicketTransfer}
      />,
      "transferTickets"
    );
  };

  const handleAddToCalendarClick = () => {
    const eventData = transformCheckoutDataForCalendar(
      orderDetailsData,
      showingLanguage
    );

    handleAddToCalendar(eventData);
  };

  return (
    orderDetailsData && (
      <div className={styles.checkoutRedirect}>
        <div className={styles.checkoutRedirectContainer}>
          <div className={styles.iconContainer}>
            <img src={orderCompletedIcon} alt="Order completed icon" />
          </div>
          <h1 className={styles.title}>Your order is completed</h1>
          <h2 className={styles.orderId}>
            Order #{orderDetailsData.transactionId}
          </h2>
          <p className={styles.orderMsg}>
            You will receive an email confirmation with your order details.
          </p>
          <div className={styles.btnsContainer}>
            <CustomButton
              title="Transfer tickets"
              variant="checkoutRedirect"
              clickHandler={handleTransferTickets}
            />
            <CustomButton
              title="Add to my calendar"
              variant="checkoutRedirectOutline"
              clickHandler={handleAddToCalendarClick}
            />
            <Link to="/account-settings/my-orders" className={styles.link}>
              Open this order in my profile
            </Link>
          </div>
        </div>
      </div>
    )
  );
}
