import ResponseMessage from "../../../components/ModalContents/ResponseMessage/ResponseMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faTriangleExclamation,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { maskString } from "../../../utils/CommonUtils";

const areAllTicketsRefunded = (tickets) => {
  console.log("tickets on areAllTicketsRefunded => ", tickets);
  // Check if all tickets are refunded
  return tickets.every((ticket) => ticket.ticketStatus === "REFUNDED");
};

const areAnyTicketsReadyToScan = (tickets) => {
  // Check if at least one of the tickets is ready to scan
  return tickets.some(
    (ticket) => ticket.ticketStatus === "READY_TO_SCAN_PURCHASED"
  );
};

export const transformOrderData = (order) => {
  console.log("order on transformOrderData => ", order);
  console.log("eventName => ", order.eventName);

  // Check if there are refunding tickets
  const refundingTicketsDetails = order.refundingTicketsDetails || null;

  // Check if the order is capturable
  const capturableBy = order.capturableBy ? new Date(order.capturableBy) : null;
  const now = new Date();
  const capturableByPassed = capturableBy ? capturableBy < now : true;
  const isOrderRefunded = areAllTicketsRefunded(order.ticketsDetails);
  console.log("isOrderRefunded => ", isOrderRefunded);

  // Transform order status to frontend status
  let frontendStatus;

  if (order.status === "canceled") {
    // The whole order was canceled
    frontendStatus = "cancelled";
  } else if (isOrderRefunded) {
    // All the tickets were refunded
    frontendStatus = "refunded";
  } else if (order.status === "requires_capture") {
    // The order is capturable, the user did not cancel it, and thus a refund is not possible
    frontendStatus = "active";
  } else if (order.status === "succeeded" && !refundingTicketsDetails) {
    frontendStatus = "active";
  } else if (order.status === "succeeded" && refundingTicketsDetails) {
    frontendStatus = "active"; // TODO: CHECK WITH RODRIGO IF THIS IS THE RIGHT STATUS MAYBE CHECK INSIDE THE TICKETS TO SEE IF ALL ARE REFUNDED
  } else {
    frontendStatus = "other";
  }

  const showViewAllReadyToScan = areAnyTicketsReadyToScan(order.ticketsDetails);

  console.log("showViewAllReadyToScan => ", showViewAllReadyToScan);

  const readyToScanTickets = order.ticketsDetails.reduce((acc, ticket) => {
    if (ticket.ticketStatus === "READY_TO_SCAN_PURCHASED") {
      acc.push(ticket.ticketId);
    }
    return acc;
  }, []);

  console.log("readyToScanTickets => ", readyToScanTickets);

  let showCancelOrder = false;
  if (order.status === "requires_capture" && !capturableByPassed) {
    showCancelOrder = true;
  }

  return {
    orderId: order.transactionId,
    orderDate: new Date(order.purchaseDate).toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }),
    // isWithinFiveMinutes: orderDateObject >= fiveMinutesBeforeMidnight,
    eventId: order.eventId,
    eventName: order.eventName,
    orderAmount: order.amountTotal,
    tickets: order.ticketsDetails || [],
    paymentIntentId: order.paymentIntentId,
    promoterStripeId: order.promoterStripeId,
    status: frontendStatus,
    isCanceled: order.status === "canceled",
    showCancelOrder,
    refundingTicketsDetails,
    startTime: order.startTime,
    promoterId: order.promoterId,
    isOrderRefunded,
    showViewAllReadyToScan,
    readyToScanTickets,
    languageArray: order.languageArray,
  };
};

// Transform the ticketDetails item from the order
export const transformTicketData = (ticket) => {
  console.log("ticket on transformTicketData => ", ticket);
  const refundPolicy = ticket.refundPolicy;
  const ticketStatus = ticket.ticketStatus;
  // const isOrderRefunded = areAllTicketsRefunded(ticket.ticketsDetails);
  const performanceDate = new Date(ticket.performanceDate);
  let refundableBy;
  if (refundPolicy.isRefundable) {
    refundableBy = new Date(
      performanceDate.getTime() - refundPolicy.daysBefore * 24 * 60 * 60 * 1000
    );
  }

  let ticketId = ticket.ticketId;
  if (ticketStatus !== "READY_TO_SCAN_PURCHASED") {
    ticketId = maskString(ticketId);
  }

  // Transform ticket status to showing status
  // Get action button text
  // TODO: TRANSFORM INTO THE RIGHT LANGUAGE WHEN LANGUAGE IS ADDED
  let showingStatus;
  let classTag;
  let actionBtnText;
  let actionBtnBehavior;
  switch (ticketStatus) {
    case "READY_TO_SCAN_PURCHASED":
      showingStatus = "Ready to Scan - Purchased";
      actionBtnText = "View Ticket";
      actionBtnBehavior = "viewTicket";
      // classTag = "active";
      break;
    case "REFUND_REQUESTED":
      showingStatus = "Refund Requested";
      classTag = "pending";
      actionBtnText = "More Info";
      actionBtnBehavior = "refundInfo";
      break;
    case "REFUNDED":
      showingStatus = "Refunded";
      classTag = "refunded";
      actionBtnText = "More Info";
      actionBtnBehavior = "refundInfo";
      break;
    case "PENDING_TRANSFER_SENDER":
      showingStatus = "Pending Transfer - Sender";
      classTag = "pending";
      break;
    case "TRANSFERRED":
      showingStatus = "Transferred";
      classTag = "inactive";
      actionBtnText = "More Info";
      actionBtnBehavior = "transferInfo";
      break;
    case "CANCELED":
      showingStatus = "Cancelled";
      classTag = "canceled";
      break;
  }

  // Define if View All Ready to Scan button should be shown

  return {
    ticketId,
    isRefundable: ticket.refundPolicy.isRefundable,
    refundableBy,
    performanceName: ticket.performanceName,
    performanceId: ticket.performanceId,
    sectionName: ticket.sectionName,
    typeName: ticket.typeName,
    showingStatus: showingStatus,
    ticketStatus,
    classTag,
    actionBtnText,
    actionBtnBehavior,
  };
};

export const getStatusModalContent = (status, onClose) => {
  if (status === "success") {
    return (
      <ResponseMessage
        icon={<FontAwesomeIcon icon={faThumbsUp} />}
        title="SUCCESS!"
        msg="Your message was successfully sent."
        onClose={onClose}
        isSuccess={true}
      />
    );
  }
  if (status === "failure") {
    return (
      <ResponseMessage
        icon={<FontAwesomeIcon icon={faTriangleExclamation} />}
        title="ERROR!"
        msg={
          <>
            <span>Error sending the message.</span>
            <span>Please try, again later.</span>
          </>
        }
        onClose={onClose}
      />
    );
  }
};
