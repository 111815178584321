import { ACCESS_TOKEN, HEADERS } from "../constants";
import { getItem, setItem, removeItem } from "./LocalStorageUtils";
import { getFormDataObj } from "./CommonUtils";
import axios from "axios";
import { parse } from "qs";
import { Category } from "@mui/icons-material";
import { formatDateToFilter } from "./CommonUtils";
import { getRoughLocation } from "./LocalizationUtils";
// import { parse } from 'dotenv';

const API_BASE_URL = process.env.REACT_APP_PATRON_API;

/**
 * Function to retrieve the authentication header including the bearer token.
 *
 * @return {Object} An object containing the authentication header.
 */
const getPatronHeaders = () => {
  // console.log("getPatronHeaders token", process.env.REACT_APP_PATRON_API_KEY);

  return {
    ...HEADERS,
    // Authorization: `Bearer ${process.env.REACT_APP_PATRON_API_KEY}`,
  };
};

const baseHeaders = {
  ...getPatronHeaders(),
  // ...HEADERS,
};

/**
 * Function performs the login with provided data and captcha response.
 *
 * @param {Object} data - An object containing user data.
 * @param {string} captchaResponse - A string representing the captcha response.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const login = (data, captchaResponse) => {
  const headers = {
    ...baseHeaders,
    "captcha-response": captchaResponse,
  };
  return axios.post(API_BASE_URL + "/authenticate", data, {
    headers: headers,
  });
};

/**
 * Function performs the login with provided data and captcha response.
 *
 * @param {Object} data - An object containing user data.
 * @return {Promise} A promise that resolves to the response from the server.
 */
export const login2 = async (data) => {
  console.log("login2");
  try {
    const headers = {
      ...baseHeaders,
      // 'captcha-response': captchaResponse,
    };
    return await axios.post(`${API_BASE_URL}/authentication/login`, data, {
      withCredentials: true,
      headers: headers,
    });
  } catch (error) {
    console.log("error on login2: ", error);
    return { error: "Error trying to login" };
    // throw error;
  }
};

/**
 * Function to register a new user with provided data and captcha response.
 *
 * @param {Object} data - An object containing user data.
 * @param {string} captchaResponse - A string representing the captcha response.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const register = (data) => {
  const headers = {
    ...baseHeaders,
    // 'captcha-response': captchaResponse,
  };
  return axios.post(API_BASE_URL + "/authentication/register", data, {
    headers: headers,
  });
};

/**
 * Function to verify the email with provided code and authentication token.
 *
 * @param {object} data - An object containing code and pendingAuthenticationToken.
 * @param {string} code - A string representing the verification code.
 * @param {string} pendingAuthenticationToken - A string representing the pending authentication token.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const verifyemail = (data) => {
  console.log("data => ", data);

  return axios.get(`${API_BASE_URL}/authentication/verify-email?`, {
    params: {
      ...data,
    },
    headers: {
      ...baseHeaders,
    },
    withCredentials: true,
  });
};

// Function to send the reset password email.
/**
 * Function to send the reset password email.
 *
 * @param {Object} data - An object containing email and captchaResponse.
 * @param {string} data.email - A string representing the user's email.
 * @param {string} data.captchaResponse - A string representing the captcha response.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const sendResetPasswordEmail = async (email) => {
  const LOGIN_HEADERS = {
    ...baseHeaders,
  };
  return axios.get(
    `${API_BASE_URL}/authentication/forgot-password?email=${email}`,
    {
      headers: LOGIN_HEADERS,
    }
  );
};

/**
 * Function to reset a new password.
 *
 * @param {Object} data - An object containing token and password.
 * @param {string} data.token - A string representing the reset token.
 * @param {string} data.password - A string representing the new password.
 * @param {string} captchaResponse - A string representing the captcha response.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const resetNewPassword = (data, captchaResponse) => {
  const LOGIN_HEADERS = {
    ...baseHeaders,
    "captcha-response": captchaResponse,
  };
  return axios.post(API_BASE_URL + "/reset-password", data, {
    headers: LOGIN_HEADERS,
  });
};

// TODO: UPDATE THIS TO UPDATE THE PASSWORD

/**
 * Function to retrieve the list of event types.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventTypes = (category) => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(API_BASE_URL + `/content/get-events-types/${category}`, {
    headers: headers,
  });
};

/**
 * Function to retrieve the list of event genres.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventGenres = () => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(API_BASE_URL + `/genre/list`, { headers: headers });
};

/**
 * Function to retrieve the location type.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getLocationTypes = () => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(API_BASE_URL + `/locationtype/list`, { headers: headers });
};

/**
 * Function to get the event detail based on event ID.
 *
 * @param {string} eventId - The ID of the event.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventDetails = async (eventId, distance, lat, lng) => {
  const headers = {
    ...baseHeaders,
  };
  const params = new URLSearchParams();
  if (distance && lat && lng) {
    params.append("distance", distance * 1000);
    params.append("lat", lat);
    params.append("lng", lng);
  } else {
    try {
      const location = await getRoughLocation();
      params.append("distance", 250000);
      params.append("lat", location.geometry.lat);
      params.append("lng", location.geometry.lng);
    } catch (error) {
      console.log("❌ error on getRoughLocation: ", error);
    }
  }
  try {
    const eventDetails = await axios.get(
      API_BASE_URL +
        `/events/get-event-details/${eventId}?${params.toString()}`,
      {
        headers: headers,
      }
    );
    return eventDetails.data;
  } catch (error) {
    console.log("error on getEventDetails: ", error);
  }
};

/**
 * Function to get the category detail of an event based on event ID.
 *
 * @param {boolean} isloggedIn - Indicates if the user is logged in.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getCategoryDetails = (isloggedIn) => {
  const headers = {
    ...baseHeaders,
  };

  return axios.get(API_BASE_URL + `/eventCategories/list`, {
    headers: headers,
  });
};

/**
 * Function to filter the event list.
 *
 * @param {Object} data - The data for filtering the event list.
 * @param {number} [page=1] - The page number of the event list.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const eventListFilter = (data, page = 1) => {
  console.log("bbbbb", data);
  const headers = {
    ...baseHeaders,
  };
  return axios.post(
    API_BASE_URL + `/event/list/filter?page=${page}&size=10`,
    data,
    { headers: headers }
  );
};

/**
 * Function to retrieve event list.
 *
 * @param {number} [page] - The page number of the event list.
 * @param {boolean} isloggedIn - Indicates if the user is logged in.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventList = (filters, page) => {
  const {
    format,
    categories,
    admissionType,
    dateType,
    searchText,
    location,
    sortBy,
    distance,
    eventTypes,
    dateRange,
  } = filters;
  // Create a URLSearchParams object to build query parameters dynamically
  console.log("DATE RANGE IS => ", dateRange);
  const params = new URLSearchParams();

  // Add only non-null filters to the query parameters
  if (format && format !== "All") {
    params.append("format", format.toLowerCase());
  }
  if (categories && !categories.includes("All")) {
    categories.forEach((category) => {
      params.append("category", category.toLowerCase());
    });
  }
  if (admissionType && admissionType !== "All")
    params.append("admissionType", admissionType.toLowerCase());
  if (dateType && dateType !== "All") {
    if (dateType === "Date Range") {
      if (dateRange) {
        params.append("dateType", dateType.toLowerCase().replace(/\s+/g, "_"));
        params.append("startDate", formatDateToFilter(dateRange[0]));
        params.append("endDate", formatDateToFilter(dateRange[1]));
      }
    } else {
      params.append("dateType", dateType.toLowerCase().replace(/\s+/g, "_"));
    }
  }
  if (searchText) params.append("searchTerm", searchText);
  if (location) {
    console.log("📍 location geometry is => ", location.geometry);
    params.append("lat", location.geometry.lat);
    params.append("lng", location.geometry.lng);
  }
  if (eventTypes && !eventTypes.includes("All")) {
    eventTypes.forEach((type) => {
      params.append("type", type);
    });
  }
  if (sortBy) params.append("sortBy", sortBy);
  if (page) params.append("page", page);
  const headers = {
    ...baseHeaders,
  };
  // console.log("DISTANCE IN GETEVENTLIST IS => ", distance);

  const queryDistance = distance ? +distance * 1000 : 250 * 1000;
  params.append("distance", queryDistance);
  return axios.get(
    `${API_BASE_URL}/events/get-events-list?${params.toString()}`,
    {
      headers: { ...headers },
      withCredentials: false,
    }
  );
};

/**
 * Function to get list of customized event.
 *
 * @param {boolean} isloggedIn - Indicates if the user is logged in.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventListCustomized = (isloggedIn) => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(API_BASE_URL + `/event/list/customized`, {
    headers: headers,
  });
};

/**
 * Function to retrieve the details of camera based on event ID.
 *
 * @param {string} eventId - The ID of the event.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getCameraDetails = (eventId) => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(API_BASE_URL + `/promoter/event/cameras/${eventId}`, {
    headers: headers,
  });
};

/**
 * Function to retrieve user details.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getUserDetails = async (userDetails) => {
  console.log("getUserDetails called");
  // Check if the cookie has expired or is close to.
  try {
    const user =
      userDetails?.message === "NOT_AUTHENTICATED" ? null : userDetails;

    console.log("user in getUserDetails => ", user);
    const shouldFetchData = await shouldItFetchDataForUserDetails();
    console.log("shouldFetchData in getUserDetails => ", shouldFetchData);

    const accessToken = getItem("accessToken");
    // Add query params to the request
    const queryParams = new URLSearchParams();
    if (accessToken) {
      queryParams.append("accessToken", accessToken);
    }
    if (shouldFetchData) {
      console.log("Fetching user info");
      const userInfo = await axios.get(
        `${API_BASE_URL}/authentication/get-user-info?${queryParams.toString()}`,
        {
          headers: { ...baseHeaders },
          withCredentials: true,
        }
      );

      console.log("FETCHED userInfo => ", userInfo);
      return userInfo.data;
    } else {
      return userDetails;
    }
  } catch (error) {
    console.log("Failed to fetch user info", error);
    return null;
  }
};

const logoutUser = async () => {
  console.log("LogoutUser called");

  const headers = {
    ...baseHeaders,
  };

  try {
    const logoutResponse = await axios.get(
      `${API_BASE_URL}/authentication/logout`,
      {
        headers: headers,
        withCredentials: true,
      }
    );

    return logoutResponse;
  } catch (error) {
    console.log("Failed to fetch user info", error);
    return null;
  }
};

const triggerGoogleAuth = () =>
  (window.location.href = `${process.env.REACT_APP_PATRON_API}/authentication/google-authentication-trigger`);
// return axios.get(
//   `${process.env.REACT_APP_PATRON_API}/authentication/google-authentication-trigger`,
//   {
//     headers: getAuthHeaders(),
//   }
// );

/**
 * Function to reset the stripe link.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const resetStripeLink = () => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(API_BASE_URL + `/reset-link`, { headers: headers });
};

/**
 * Function to update the contact information of the user.
 *
 * @param {Object} data - The data containing the contact information.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const updateContactInfo = async (data) => {
  console.log("update contact info with data => ", data);
  // const formData = getFormDataObj(data);
  const updateResponse = await axios.put(
    API_BASE_URL + `/authentication/update-contact-info`,
    data,
    {
      headers: { ...baseHeaders },
      withCredentials: true,
    }
  );

  console.log("update contact info response => ", updateResponse);
  return updateResponse;
};

/**
 * Function to retrieve the methods to make a payment.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const fetchPaymentMethods = () => {
  return axios.get(API_BASE_URL + `/getPaymentMethods`, {
    headers: { ...baseHeaders },
  });
};

/**
 * Function to pay with already added payment method.
 *
 * @param {Object} paymentBody - The payment body containing the payment details.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const payWithExistingMethod = (paymentBody) => {
  return axios.post(API_BASE_URL + `/payment`, paymentBody, {
    headers: { ...baseHeaders },
  });
};

/**
 * Saves the billing information for a specific event.
 *
 * @param {number} eventId - The ID of the event.
 * @param {Object} billingDetails - The billing details to be saved.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const saveBillingInfo = (eventId, billingDetails) => {
  return axios.post(
    API_BASE_URL + `/checkout/billingInfo/${eventId}`,
    billingDetails,
    { headers: { ...baseHeaders } }
  );
};

/**
 * Validates a coupon for a specific event.
 *
 * @param {number} eventId - The ID of the event.
 * @param {string} couponCode - The coupon code to be validated.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const validateCoupon = (eventId, couponCode) => {
  return axios.post(
    API_BASE_URL + `/coupon/validate/${eventId}?coupon=${couponCode}`,
    {},
    { headers: { ...baseHeaders } }
  );
};

/**
 * Retrieves the list of orders made by the user.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getOrderList = async (filters) => {
  console.log("filters: ", filters);
  try {
    const orderListResponse = await axios.get(
      API_BASE_URL + `/user-tickets/get-orders`,
      {
        withCredentials: true,
        headers: { ...baseHeaders },
        params: {
          searchQuery: filters.searchQuery,
          orderDate: filters.orderDate,
        },
      }
    );

    return orderListResponse;

    // return res.data;
  } catch (err) {
    console.log(err);
    const error = new Error(err.response.data.message);
    error.status = err.status;
    console.log(error);
    return error;
  }
};

/**
 * Cancels an order made by the user.
 *
 * @param {Object} orderDetails - The details of the order to be cancelled.
 * @param {string} paymentIntentId - The ID of the payment intent.
 * @param {string} promoterStripeId - The ID of the promoter.
 * @param {string} cancelReason - The reason for the cancellation.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const cancelOrder = (orderDetails) => {
  return axios.delete(`${API_BASE_URL}/user-tickets/cancel-order`, {
    data: orderDetails,
    withCredentials: true,
    headers: { ...baseHeaders },
  });
};

/**
 * Retrieves the details of the fee charged for an order.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getFeeDetails = () => {
  return axios.get(API_BASE_URL + `/order/fee/details`, {
    headers: { ...baseHeaders },
  });
};

const googleCallbackFetchData = async (session) => {
  // Session is an object with access token and refresh token
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PATRON_API}/authentication/google-callback`,
      session,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          ...baseHeaders,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Failed to fetch user info", error);
  }
};

const sendContactMessage = async (data) => {
  try {
    const contactResponse = await axios.post(
      `${process.env.REACT_APP_PATRON_API}/contact/contact-us-message`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          ...baseHeaders,
        },
      }
    );

    return contactResponse;
  } catch (error) {
    console.log("Failed to fetch user info", error);
  }
};

const getContentForPage = async (page) => {
  const requestURL = `${process.env.REACT_APP_PATRON_API}/content/get-content-for-page/${page}`;
  console.log("requestURL: ", requestURL);
  try {
    const contentResponse = await axios.get(requestURL, {
      headers: {
        // 'Content-Type': 'application/json',
        ...baseHeaders,
        // withCredentials: false,
      },
    });
    console.log("getContentForPage: ", contentResponse.data.content);
    return contentResponse.data.content;
  } catch (error) {
    console.log("Error on getContentForPage: ", error);
  }
};

// Checks if the user credentials are valid
// Receives an object with the user's email and password
const checkUserCredentials = async (data) => {
  console.log("checkUserCredentials: ", data);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PATRON_API}/authentication/check-user-credentials`,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          ...baseHeaders,
        },
      }
    );
    console.log("⏎ response.data on checkUserCredentials => ", response);

    return { success: true };
  } catch (error) {
    console.log("Failed to fetch user info", error);
    return { success: false, message: error.response.data.message };
  }
};

// Close the WorkOS account and change the DB to turn the user into Inactive.
// Receives an object with the user's email and the reason for closing.
const closePatronAccount = async (data) => {
  try {
    const response = await axios.delete(
      API_BASE_URL + `/authentication/close-account`,

      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          ...baseHeaders,
        },
        data: data,
      }
    );
    return response;
  } catch (error) {
    console.log("Error on closePatronAccount: ", error);
    return { error: error };
  }
  return;
};

// Update login info from a user that is authenticated using email/password
const updateLoginInfo = async (data) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/authentication/update-login-info`,
      data,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          ...baseHeaders,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update login info");
    }

    return response;
  } catch (error) {
    console.log("Error on updateLoginInfo: ", error.message, error);
    return { success: false, message: error.message };
  }
};

// Function to check expiration
const shouldItFetchDataForUserDetails = async (userDetails) => {
  if (!userDetails) {
    return true;
  }
  const expiration = localStorage.getItem("sessionExpirationDate");
  console.log("sessionExpirationDate => ", expiration);
  if (!expiration || Date.now() > new Date(parseInt(expiration))) {
    console.log("canceling session");
    localStorage.removeItem("expirationDate");
    return true;
  }

  // Check if the cookie expiration date is less than 5 minutes from now
  if (
    expiration &&
    Date.now() > new Date(parseInt(expiration)) - 10 * 60 * 1000
  ) {
    console.log("renewing session");
    const renewedSessionData = await renewSession(); // Trigger token renewal if close to expiration
    setItem("sessionExpirationDate", renewedSessionData.sessionExpirationDate);
    return true;
  } else {
    return false;
  }
};

// Renewing session
const renewSession = async () => {
  try {
    console.log("renewSession called");
    let rememberPatronUser = localStorage.getItem("rememberPatronUser")
      ? true
      : false;
    const accessToken = getItem("accessToken");

    // add access token to the request as a query param
    const queryParams = new URLSearchParams();
    if (accessToken) {
      queryParams.append("accessToken", accessToken);
    }

    // Check if the cookie expiration date is less than 5 minutes from now
    const response = await axios.post(
      `${API_BASE_URL}/authentication/renew-session?${queryParams.toString()}`,
      { rememberUser: rememberPatronUser },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          ...baseHeaders,
        },
      }
    );

    if (response.status === 200) {
      const newSessionData = response.data;
      console.log("newSessionData => ", newSessionData);

      console.log("session renewed");
      return newSessionData;
    } else {
      console.log("session could not be renewed");
      return false;
    }
  } catch (error) {
    return false;
  }
};

const getPerfomanceDetails = async (performanceId) => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/events/get-performance-details/${performanceId}`;
  const headers = {
    ...baseHeaders,
  };
  try {
    const performanceDetails = await axios.get(requestUrl, {
      headers: headers,
    });
    return performanceDetails.data;
  } catch (error) {
    console.log("error on getPerformanceDetails: ", error);
  }
};

const getTicketsByPerformanceId = async (performanceId) => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/events/get-tickets-by-performance-id/${performanceId}`;
  const headers = {
    ...baseHeaders,
  };
  try {
    const tickets = await axios.get(requestUrl, {
      headers: headers,
    });

    return tickets.data;
  } catch (error) {
    console.log("error on getTicketsByPerformanceId: ", error);
  }
};
/*
  Adds items to the cart in the backend
  Each item is an object with: sectionId, selectedQuantity, typeId, performanceId
  It returns a promise that resolves in an object with: 
  eventId: string;
  eventName: string;
  performanceId: string;
  performanceName: string;
  cartId: string;
  experiredAt: string;
  ticketItems?: TicketItem[];
*/
const addToCart = async (items) => {
  console.log("Items to add to cart: ", items);

  const requestUrl = `${process.env.REACT_APP_PATRON_API}/events/add-items-to-cart`;
  const headers = {
    ...baseHeaders,
  };
  const addToCartResponse = await axios.post(requestUrl, items, {
    headers: headers,
  });
  return addToCartResponse.data;
};

const getCartDataByCartId = async () => {
  const cartId = getItem("cartId");
  if (cartId) {
    const requestUrl = `${process.env.REACT_APP_PATRON_API}/events/get-cart-data-by-cart-id/${cartId}`;
    const headers = {
      ...baseHeaders,
    };
    const addToCartResponse = await axios.get(requestUrl, {
      headers: headers,
    });
    return addToCartResponse.data;
  }
  console.log("No cartId found");
  return null;
};

const removeItemsFromCart = async (items) => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/events/remove-item-from-cart`;
  const headers = {
    ...baseHeaders,
  };
  const removeItemsFromCartResponse = await axios.put(requestUrl, items, {
    headers: headers,
  });
  console.log(
    "removeItemsFromCartResponse.data: ",
    removeItemsFromCartResponse.data
  );

  return removeItemsFromCartResponse.data;
};

const addPatronIdToCart = async (data) => {
  const { patronId, cartId } = data;
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/events/add-patron-id-to-cart`;
  const headers = {
    ...baseHeaders,
  };
  //TODO: SEND THE COOKIE WITH THE REQUEST TO GUARANTEE THE USER IS LOGGED IN
  const addPatronIdToCartResponse = await axios.put(
    requestUrl,
    { patronUserId: patronId, cartId: cartId },
    {
      headers: headers,
    }
  );
  return addPatronIdToCartResponse.data;
};

const getCartDataByPatronId = async (patronId) => {
  if (patronId) {
    console.log("getCartDataByPatronId called with patronId => ", patronId);

    const requestUrl = `${process.env.REACT_APP_PATRON_API}/events/get-cart-data-by-patron-id/${patronId}`;
    const headers = {
      ...baseHeaders,
    };
    const addToCartResponse = await axios.get(requestUrl, {
      headers: headers,
    });
    return addToCartResponse.data;
  }
  console.log("No cartId found");
  return null;
};
/* 
  Make an API call to generate the client secret for Stripe Checkout
  data needs to contain:
  eventId: string;
  cartId: string;};
  promoterId: string;
  tickets: {
    name: string;
    taxCode: string;
    price: number;
    quantity: number;
  }[]
*/
const checkoutHandler = async (data) => {
  console.log("checkoutHandler called with data => ", data);
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/stripe/checkout`;
  console.log("requestUrl: ", requestUrl);

  const headers = {
    ...baseHeaders,
  };
  const checkoutResponse = await axios.post(requestUrl, data, {
    withCredentials: true,
    headers: headers,
  });
  console.log("checkoutResponse: ", checkoutResponse.data);

  return checkoutResponse.data;
};

// Make an API call to delete the cart
const deleteCart = async () => {
  const cartId = getItem("cartId");
  if (!cartId) {
    console.log("No cartId found");
    return null;
  }
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/events/delete-cart/${cartId}`;
  const headers = {
    ...baseHeaders,
  };
  try {
    const cancelCartResponse = await axios.delete(requestUrl, {
      headers: headers,
    });
    console.log("cancelCartResponse: ", cancelCartResponse.data);
    removeItem("cartId");
    removeItem("cartExpiration");
    return cancelCartResponse.data;
  } catch (error) {
    console.log("error on deleteCart: ", error);
  }
};

// Make an API call to get the tickets from a user. It receives the tickets owned and the tickets purchased
const getUserTickets = async () => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/user-tickets/get-user-tickets`;
  const options = {
    ...baseHeaders,
    withCredentials: true,
  };
  try {
    const userTickets = await axios.get(requestUrl, options);
    console.log("userTickets.data =>  ", userTickets.data);

    return userTickets.data;
  } catch (error) {
    console.log("error on getUserTickets: ", error);
  }
};

// Make an API call to create a ticket transfer
const createTicketTransfer = async (data) => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/user-tickets/create-ticket-transfer`;
  const options = {
    ...baseHeaders,
    withCredentials: true,
  };
  try {
    console.log("data => ", data);

    const transferResponse = await axios.post(requestUrl, data, options);
    console.log("transferResponse.data =>  ", transferResponse.data);
    return transferResponse.data;
  } catch (error) {
    console.log("error on createTicketTransfer: ", error);
    return { message: "Error trying to create ticket transfer", error };
  }
};

// Make an API call to get the receiving tickets from a user
const getReceivingTickets = async () => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/user-tickets/get-receiving-tickets`;
  const options = {
    ...baseHeaders,
    withCredentials: true,
  };
  try {
    const receivingTickets = await axios.get(requestUrl, options);
    console.log("receivingTickets.data =>  ", receivingTickets.data);
    return receivingTickets.data;
  } catch (error) {
    console.log("error on getUserTickets: ", error);
  }
};

// Accept a ticket transfer
const acceptTicketTransfer = async (acceptedTickets) => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/user-tickets/accept-ticket-transfer`;
  const options = {
    ...baseHeaders,
    withCredentials: true,
  };
  try {
    const transferResponse = await axios.put(
      requestUrl,
      { ticketIds: acceptedTickets },
      options
    );
    console.log("transferResponse.data =>  ", transferResponse.data);
    return transferResponse.data;
  } catch (error) {
    console.log("error on acceptTicketTransfer: ", error);
    return { message: "Error trying to accept ticket transfer", error };
  }
};

// Request a receipt for an invoice
const requestReceipt = async (paymentIntentId, promoterStripeId) => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/user-tickets/request-receipt?paymentIntentId=${paymentIntentId}&promoterStripeId=${promoterStripeId}`;
  const options = {
    ...baseHeaders,
    withCredentials: true,
  };
  try {
    const receiptResponse = await axios.get(requestUrl, options);
    console.log("receiptResponse.data: ", receiptResponse.data);
    return receiptResponse.data;
  } catch (error) {
    console.log("error on requestReceipt: ", error);
  }
};

// Request a refund
const requestRefund = async (requestData) => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/user-tickets/refund-request`;
  const options = {
    ...baseHeaders,
    withCredentials: true,
  };
  try {
    console.log("requestData requestData => ", requestData);

    const requestRefundResponse = await axios.post(
      requestUrl,
      requestData,
      options
    );
    console.log("requestRefundResponse.data =>  ", requestRefundResponse.data);
    return requestRefundResponse.data;
  } catch (error) {
    console.log("Error on requestRefund: ", error);

    if (error.response) {
      // API responded with a 4xx or 5xx status code
      throw new Error(
        `Refund request failed: ${
          error.response.data.message || "Unknown error"
        }`
      );
    } else if (error.request) {
      // No response was received
      throw new Error("No response received from the server.");
    } else {
      // Something else happened
      throw new Error("Error trying to send a refund request.");
    }
  }
};

// Get the order details by sessionId - Used in CheckoutRedirect to get the order details for a completed order
const getOrderDetailsBySessionId = async (sessionId) => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/user-tickets/get-purchase-data-by-session-id/${sessionId}`;
  const options = {
    ...baseHeaders,
    withCredentials: true,
  };
  const orderDetails = await axios.get(requestUrl, options);
  return orderDetails.data;
};

// Get tickets status by tickets ids
const getTicketsStatus = async (ticketIds) => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/user-tickets/get-status`;

  const ticketIdsParam = new URLSearchParams();
  ticketIds.forEach((id) => {
    ticketIdsParam.append("ticketIds", id);
  });

  const options = {
    ...baseHeaders,
    withCredentials: true,
    params: ticketIdsParam,
  };
  const ticketsStatus = await axios.get(requestUrl, options);
  return ticketsStatus.data;
};

// Contact Organizer
const contactOrganizer = async (data) => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/user-communication/contact-organizer`;
  const options = {
    ...baseHeaders,
    withCredentials: true,
  };
  const contactResponse = await axios.post(requestUrl, data, options);
  return contactResponse.data;
};

// Get transaction ticket status
const getClaimStatus = async (transactionId, ticketId) => {
  try {
    const requestUrl = `${process.env.REACT_APP_PATRON_API}/user-tickets/claim-ticket-status/${transactionId}`;
    const options = {
      ...baseHeaders,
      params: { ticketId },
      withCredentials: true,
    };

    const response = await axios.get(requestUrl, options);

    console.log("transactionTicketStatus.data: ", response.data);

    return response.data;
  } catch (error) {
    console.error("Error fetching claim status:", error);
    return null;
  }
};

// Get claims by patron
const getClaimsByPatron = async () => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/user-tickets/get-refund-requests-by-patron-id`;
  const options = {
    ...baseHeaders,
    withCredentials: true,
  };
  const claims = await axios.get(requestUrl, options);
  return claims.data;
};

// Fetch Promoter Landing Page Content
const getPromoterLandingPageContent = async (identifier, type) => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/content/get-promoter-landing-page-content/${identifier}`;
  const options = {
    ...baseHeaders,
    params: { type },
  };
  const content = await axios.get(requestUrl, options);
  return content.data;
};

// Retrieves the events categories for the events filter and any other places the info is needed
const getEventsCategories = async () => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/content/get-events-categories`;
  const options = {
    ...baseHeaders,
  };

  const eventsCategoriesResponse = await axios.get(requestUrl, options);
  return eventsCategoriesResponse.data;
};

const parseHashParams = () => {
  const hash = window.location.hash.substring(1); // remove '#'
  const params = new URLSearchParams(hash);
  return {
    access_token: params.get("access_token"),
    refresh_token: params.get("refresh_token"),
    expires_in: params.get("expires_in"),
    token_type: params.get("token_type"),
    provider_token: params.get("provider_token"),
  };
};

// Invalidate the session and refreshToken
const invalidateSession = async (access_token) => {
  const requestUrl = `${process.env.REACT_APP_PATRON_API}/authentication/invalidate-access-token`;
  const options = {
    ...baseHeaders,
  };
  const invalidateSessionResponse = await axios.post(
    requestUrl,
    { accessToken: access_token },
    options
  );
  return invalidateSessionResponse.data;
};

// Reset Password
const getPasswordSession = async (data) => {
  try {
    console.log("🔍 getPasswordSession called with:", data);
    const options = {
      ...baseHeaders,
    };
    const params = new URLSearchParams();
    params.append("token_hash", data.tokenHash);
    params.append("type", data.type);
    const requestUrl = `${
      process.env.REACT_APP_PATRON_API
    }/authentication/get-reset-password-session?${params.toString()}`;
    console.log("🔍 Request URL:", requestUrl);

    const getPasswordSessionResponse = await axios.get(requestUrl, options);
    console.log(
      "✅ getPasswordSession response:",
      getPasswordSessionResponse.data
    );
    return getPasswordSessionResponse.data;
  } catch (error) {
    console.error("❌ Error in getPasswordSession:", error);
    console.error(
      "❌ Error details:",
      error.response ? error.response.data : "No response data"
    );
    throw error;
  }
};
// Export all API Functions.
export {
  login,
  register,
  sendResetPasswordEmail,
  verifyemail,
  resetNewPassword,
  triggerGoogleAuth,
  getEventTypes,
  getEventGenres,
  getLocationTypes,
  getEventDetails,
  getEventList,
  getEventListCustomized,
  getCameraDetails,
  getUserDetails,
  logoutUser,
  resetStripeLink,
  updateContactInfo,
  fetchPaymentMethods,
  payWithExistingMethod,
  validateCoupon,
  saveBillingInfo,
  getOrderList,
  cancelOrder,
  getFeeDetails,
  getCategoryDetails, //Category Filtering
  eventListFilter, // Filtering events
  googleCallbackFetchData,
  sendContactMessage,
  getContentForPage,
  checkUserCredentials,
  closePatronAccount,
  updateLoginInfo,
  shouldItFetchDataForUserDetails,
  getPerfomanceDetails,
  getTicketsByPerformanceId,
  addToCart,
  getCartDataByCartId,
  removeItemsFromCart,
  addPatronIdToCart,
  getCartDataByPatronId,
  checkoutHandler,
  deleteCart,
  getUserTickets,
  createTicketTransfer,
  getReceivingTickets,
  acceptTicketTransfer,
  requestReceipt,
  getOrderDetailsBySessionId,
  requestRefund,
  getTicketsStatus,
  contactOrganizer,
  getClaimStatus,
  getClaimsByPatron,
  getPromoterLandingPageContent,
  getEventsCategories,
  parseHashParams,
  invalidateSession,
  getPasswordSession,
};
