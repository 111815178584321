import styles from "./Performance.module.scss";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getPerfomanceDetails } from "../../utils/APIUtils";
import { useQuery } from "@tanstack/react-query";
import EventPerformanceHeader from "../../components/EventPerformanceHeader/EventPerformanceHeader";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../context/useLanguage";
import PerformanceCard from "../../components/PerformanceCard/PerformanceCard";
import AboutEventPerfomance from "../../components/AboutEventPerfomance/AboutEventPerfomance";
import ImagesSection from "../../components/ImagesSection/ImagesSection";
import VideoContainer from "../../components/VideoSection/VideoSection";
import SocialSection from "../../components/SocialSection/SocialSection";
import VIPSection from "../../components/VIPSection/VIPSection";
import SponsorCardsContainer from "../../components/SponsorCardsContainer/SponsorCardsContainer";
import { loadImage, getShowingLanguage } from "../../utils/CommonUtils";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocationSection from "../../components/LocationSection/LocationSection";
import { useModal } from "../../context/useModal";
import SelectTickets from "../../components/SelectTickets/SelectTickets";
import { transformPerfomanceData } from "./performanceTransformation";
import Spinner from "../../components/ModalContents/Spinner/Spinner";
export default function Performance() {
  const { performanceId } = useParams();
  const [performanceImgs, setPerformanceImgs] = useState([]);
  const [banner, setBanner] = useState(null);
  const { language } = useLanguage();
  const navigate = useNavigate();
  const { openModal } = useModal();

  console.log("performanceId: ", performanceId);

  const {
    data: performanceDetails,
    isFetching,
    error,
  } = useQuery({
    queryKey: ["performanceDetails", performanceId],
    queryFn: async () => getPerfomanceDetails(performanceId),
  });

  // console.log('performanceDetails: ', performanceDetails);

  const showingLanguage = getShowingLanguage(
    performanceDetails?.details.contentLanguage,
    language
  );

  // TODO: ADD ERROR HANDLER FOR FETCHING DATA

  const handleTicketClick = () => {
    console.log("handleTicketClick called");
    openModal(
      <SelectTickets
        performanceDetails={performanceDetails}
        showingLanguage={showingLanguage}
      />,
      "selectTickets"
    );
  };

  // Load event images
  useEffect(() => {
    if (!isFetching && performanceDetails) {
      const descriptionImgURLs = [];
      const bannerURL = `${process.env.REACT_APP_IMG_BASE_URL}${performanceDetails.details.mainImages[0]}/public`;
      loadImage(bannerURL, null, setBanner);

      if (
        performanceDetails.details.description &&
        performanceDetails.details.description.images
      ) {
        performanceDetails.details.description?.images
          .slice(0, 4)
          .forEach((imgId) => {
            const imgURL = `${process.env.REACT_APP_IMG_BASE_URL}${imgId}/public`;
            console.log("imgURL: ", imgURL);

            loadImage(imgURL, null, (img) => descriptionImgURLs.push(img));
          });

        setPerformanceImgs(descriptionImgURLs);
      }
    }
  }, [isFetching, performanceDetails, performanceId]);
  if (!isFetching && !performanceDetails) {
    navigate("/error");
  }

  if (!isFetching) {
    console.log("perfomanceDetails: ", performanceDetails);
  }

  const {
    eventTitle,
    performanceTitle,
    startTime,
    startingPrice,
    promoterName,
    promoterLink,
    location,
    aboutPerformance,
    videos,
    socials,
    otherPerformances,
    sponsors,
    eventLink,
  } = !isFetching && transformPerfomanceData(performanceDetails);

  console.log("performanceImgs: ", performanceImgs);
  console.log("performanceImgs?.length > 0 : ", performanceImgs?.length > 0);

  return (
    <div className={styles.performance}>
      {isFetching ? (
        <Spinner /> // TODO: Add loading animation while fetching data
      ) : (
        <div className={styles.performanceContainer}>
          <Link className={styles.eventDirection} to={eventLink}>
            <div className={styles.eventDirectionContainer}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span>{eventTitle[showingLanguage]}</span>
            </div>
          </Link>
          <EventPerformanceHeader
            banner={banner}
            title={performanceTitle[showingLanguage]}
            startTime={startTime}
            shareFunction={() => console.log("share")}
            startingPrice={startingPrice}
            sponsors={sponsors}
            promoterName={promoterName}
            promoterLink={promoterLink}
            eventId={performanceId}
            language={showingLanguage}
            location={location}
            onTicketClick={handleTicketClick}
          />
          {/* Temporary Button to access the select tickets */}
          <button onClick={handleTicketClick}>Tickets</button>
          {aboutPerformance && (
            <AboutEventPerfomance
              content={aboutPerformance[showingLanguage] || ""}
            />
          )}
          {performanceImgs && performanceImgs?.length > 0 && (
            <ImagesSection images={performanceImgs} />
          )}
          {videos && videos.length > 0 && (
            <VideoContainer videoId={videos[0].id} />
          )}
          {socials && socials.length > 0 && (
            <SocialSection socialMediaAccounts={socials} />
          )}
          <VIPSection />
          {/* ADD LOCATION SECTION */}
          {/* <LocationSection location={performanceDetails.location} /> */}
          {otherPerformances && otherPerformances.length > 0 && (
            <section className={styles.performances}>
              <div className={styles.performancesContainer}>
                <div className={styles.performancesCards}>
                  <h3>Performances</h3>
                  <div className={styles.performancesCardsContainer}>
                    {otherPerformances.map((performance) => (
                      <PerformanceCard
                        key={performance.id}
                        performance={performance}
                        imgId={performanceDetails.event.performances[0] || null}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </section>
          )}
          {sponsors && sponsors.length > 0 && (
            <section className={styles.sponsors}>
              <SponsorCardsContainer
                sponsors={performanceDetails.event.sponsors}
              />
            </section>
          )}
        </div>
      )}
    </div>
  );
}
