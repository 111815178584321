import styles from "./ClaimCard.module.scss";
import {
  getLanguageArray,
  getShowingLanguage,
} from "../../../utils/CommonUtils";
import { transformClaimPartialDetails } from "./transformClaimsData";
import { useLanguage } from "../../../context/useLanguage";
import CustomButton from "../../../components/CustomButton/CustomButton";
import ClaimDetails from "./ClaimDetails";
import { useModal } from "../../../context/useModal";

export default function ClaimCard({ claim }) {
  const {
    status,
    statusText,
    eventName,
    performanceName,
    sectionName,
    typeName,
    ticketId,
    transactionId,
    showingTicketId,
    requestDate,
  } = transformClaimPartialDetails(claim);
  const { openModal } = useModal();
  const { language } = useLanguage();
  const languageArray = getLanguageArray(eventName);
  const showingLanguage = getShowingLanguage(languageArray, language);

  const handleShowRefundInfo = () => {
    console.log("handleShowRefundInfo");
    openModal(
      <ClaimDetails ticketId={ticketId} transactionId={transactionId} />,
      "whiteFullWidth"
    );
  };

  // Create a class name based on the status for the status tag
  const statusTagClass = `${styles.refundStatusTag} ${styles[status]}`;
  return (
    <>
      <div className={styles.claimCard}>
        <div className={styles.claimDetailsContainer}>
          <section className={styles.details}>
            {/* <div className={styles.detailsHeader}>
            <span className={styles.eventLabel}>Event</span>
            <span className={styles.eventName}>
            {eventName[showingLanguage]}
            </span>
            </div> */}
            <div className={styles.detailsTicket}>
              <div className={styles.detailsTicketLine}>
                <span
                  className={styles.ticketId}
                >{`Ticket # ${showingTicketId}`}</span>
                <span className={statusTagClass}>{statusText}</span>
              </div>
              {/* <div className={styles.orderContainer}>
              <span className={styles.orderLabel}>Order ID</span>
              <span className={styles.orderId}>{transactionId}</span>
              </div> */}
            </div>
            <div className={styles.detailsPerformance}>
              <div className={styles.detailColumn}>
                <span className={styles.label}>Order Id</span>
                <span className={styles.data}>{transactionId}</span>
              </div>
              <div className={styles.detailColumn}>
                <span className={styles.label}>Event</span>
                <span className={styles.data}>
                  {eventName[showingLanguage]}
                </span>
              </div>
              <div className={styles.detailColumn}>
                <span className={styles.label}>Request Date</span>
                <span className={styles.data}>{requestDate}</span>
              </div>
            </div>
            <div className={styles.detailsBtnContainer}>
              <CustomButton
                clickHandler={handleShowRefundInfo}
                title="Details"
                variant="orderCard"
              />
            </div>
          </section>
        </div>
      </div>
      {/* <div className={styles.claimCardSeparator}></div> */}
    </>
  );
}
