import styles from "./Claims.module.scss";
import { useQuery } from "@tanstack/react-query";
import { getClaimsByPatron } from "../../../utils/APIUtils";
import Spinner from "../../../components/ModalContents/Spinner/Spinner";
import ClaimCard from "./ClaimCard";
import ResponseMessage from "../../../components/ModalContents/ResponseMessage/ResponseMessage";
import { getFetchStatusIcon } from "../../../utils/iconUtils";
import { useAuth } from "../../../context/useAuth";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";

export default function Claims() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const {
    isLoading: isClaimsLoading,
    error: claimsError,
    data: claims,
    isError: isClaimsError,
  } = useQuery({ queryKey: ["claims"], queryFn: getClaimsByPatron });

  // If the user is not logged in, redirect to the login page
  if (!user) {
    navigate("/login");
    return null;
  }

  // Render loading if isLoading
  if (isClaimsLoading) return <Loading />;

  // Render error if isError
  if (isClaimsError)
    return (
      <div className={styles.feedbackContainer}>
        <ResponseMessage
          notModal={true}
          title="Error!"
          msg="Could not fetch claims."
          icon={getFetchStatusIcon("error")}
        />
      </div>
    );

  console.log("claims => ", claims);

  return (
    <div className={styles.claims}>
      <div className={styles.claimsContainer}>
        <div className={styles.claimsHeader}>
          <h2>Claims</h2>
        </div>
        <ul className={styles.claimsList}>
          {claims?.map((claim, index) => {
            return (
              <li key={`claim-${index}`} className={styles.claim}>
                <ClaimCard claim={claim} isLast={index === claims.length - 1} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
