import { displayName } from "react-quill";

export const transformEventDashboardData = (eventData, location) => {
  console.log("eventData in transformEventDashboardData => ", eventData);
  console.log("location in transformEventDashboardData => ", location);
  console.log(
    "totalCount in transformEventDashboardData => ",
    eventData.data.totalCount
  );
  const locationString =
    location && "countryCode" in location
      ? `${location.city}, ${location.countryCode}`
      : location.city;

  return {
    events: eventData.data.events,
    locationString,
    totalCount: eventData.data.totalCount,
  };
};
