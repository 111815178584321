import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import FormInput from "../../components/FormInput/FormInput";
import { useAuth } from "../../context/useAuth";
import AuthenticationHead from "../../components/AuthenticationHead/AuthenticationHead";
import AuthenticationFoot from "../../components/AuthenticationFoot/AuthenticationFoot";
import KeepMeSignAndForgot from "../../components/KeepMeSignAndForgot/KeepMeSignAndForgot";
import ConfirmVerificationCode from "../../components/ConfirmVerificationCode/ConfirmVerificationCode";
import styles from "./Login.module.scss";
import { loginValidation } from "../../utils/authValidationUtils";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { login2 } from "../../utils/APIUtils";
import * as storageUtils from "../../utils/LocalStorageUtils";
import Loading from "../../components/Loading/Loading";

/**
 * Login function is a React functional component that exports a default function
 * named Login. This component is responsible for handling user login functionality.
 *
 * @return {JSX.Element} Returns a JSX element that renders the login form.
 */
export default function Login() {
  const auth = useAuth();

  const navigate = useNavigate();

  // States for controlled components
  const [email, setEmail] = useState("diegotertomartins@gmail.com");
  const [password, setPassword] = useState("Test-123");
  const [rememberUser, setRememberUser] = useState(false);
  // Remaining states
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();

  // Get verified from URL queries
  const [searchParams] = useSearchParams();
  const verified = searchParams.get("verified");
  const emailFromUrl = searchParams.get("email");
  console.log("❌ verified => ", verified);
  console.log("❌ emailFromUrl => ", emailFromUrl);

  /* 
  useEffect to handle the login page
  - Show message to user to verify their email if the email is not verified
  - gets the email from the url if it exists and sets the email state
  - gets the email from the local storage if it exists and sets the email state in case the email from the url is not present
*/
  useEffect(() => {
    if (verified === "false") {
      // Show message to user to verify their email
      console.log("✅ Email not verified in login page");
      setErrors({
        auth: "Please, verify your email.",
      });
    }

    const prevUser = storageUtils.getItem("email");
    if (emailFromUrl) {
      setEmail(emailFromUrl);
    } else if (prevUser) {
      setEmail(prevUser);
    }
  }, []);

  useEffect(() => {
    console.log("👤 user in Login useEffect is => ", auth.user);
    if (auth.user) {
      navigate("/events");
    }
  }, [auth.user]);

  /**
   * A function that handles login errors.
   *
   * @param {Error} err - The error object.
   * @return {void}
   */
  const handleLoginError = () => {
    const errorMsg = "Could not login. Verify your credentials and try again.";
    setErrors((prevErrors) => {
      return { ...prevErrors, auth: errorMsg };
    });
  };
  const {
    mutateAsync: login,
    isPending: isLoginPending,
    error: loginUserError,
    isError: isUpdateUserError,
  } = useMutation({
    mutationFn: async (user) => login2(user),

    onError: (err) => {
      console.log("❌ login error => ", err);

      handleLoginError();
    },
    onSuccess: (response) => {
      console.log("🔙 login response => ", response);
      if ("error" in response) {
        handleLoginError();
        return;
      }
      const loginResponseData = response.data;
      console.log("loginResponseData => ", loginResponseData);
      if ("VERIFY_EMAIL" in loginResponseData) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            auth: "Please, verify your email.",
          };
        });
      } else {
        queryClient.invalidateQueries({ queryKey: ["userDetails"] });
        console.log("passed login invalidateQueries");
        navigate("/events");
      }
    },
  });

  if (isUpdateUserError) {
    handleLoginError();
  }

  /**
   * Resets the form state.
   *
   * @return {void} This function does not return anything.
   */
  const resetFormState = () => {
    setErrors({});
    setEmail("");
    setPassword("");
    // setEmailVerificationCode("");
    // setPendingAuthToken("");
  };

  /**
   * A function that submits the form.
   *
   * @return {void} This function does not return anything.
   */
  const submitForm = async (e) => {
    e.preventDefault();
    console.log("submitForm called");

    const errs = loginValidation(email, password);
    if (Object.keys(errs).length === 0) {
      const loginData = {
        email: email,
        password: password,
        rememberUser: rememberUser,
      };
      if (rememberUser) {
        storageUtils.setItem("email", email);
        storageUtils.setItem("rememberPatronUser", true);
      }
      await login(loginData);
    } else {
      setErrors(errs);
    }
  };

  /**
   * Clears the error state when the user starts typing.
   *
   * @return {void} This function does not return anything.
   */
  const clearErrorWhenTyping = () => {
    if (Object.keys(errors).length > 0) {
      setErrors({});
    }
  };

  // Show loading spinner while isCancelAccountPending
  if (isLoginPending) {
    return <Loading />;
  }

  return (
    <div className={styles.login}>
      {/* {!pendingAuthToken ? ( */}
      <div className={styles.loginCard}>
        <AuthenticationHead isLogin={true} />
        <form onSubmit={submitForm}>
          <FormInput
            ariaLabel={"username"}
            controlId={"loginEmail"}
            label="Username"
            type="email"
            placeholder="Enter your email address"
            value={email}
            onInputChange={setEmail}
            error={errors.email}
            onClearError={clearErrorWhenTyping}
          />
          <FormInput
            ariaLabel={"password"}
            controlId={"loginPassword"}
            label={"Password"}
            type={"password"}
            placeholder={"Password"}
            value={password}
            onInputChange={setPassword}
            minLength={8}
            error={errors.password || errors.auth}
            onClearError={clearErrorWhenTyping}
          />
          <KeepMeSignAndForgot
            onChangeChecked={setRememberUser}
            rememberUser={rememberUser}
          />
          <p className={styles.legal}>
            {" "}
            By continuing past this page, you agree to the Terms of Use and
            understand that information will be used as described in our Privacy
            Policy.
          </p>
          <CustomButton type={"submit"} title={"Log In"} />
        </form>
        <AuthenticationFoot />
      </div>
    </div>
  );
}
