import React, { useEffect } from "react";
import { useAuth } from "../../context/useAuth";
import { useNavigate } from "react-router-dom";
import { googleCallbackFetchData } from "../../utils/APIUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Loading from "../../components/Loading/Loading";
import { parseHashParams } from "../../utils/APIUtils";

export default function OAuth2Redirect() {
  const auth = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    mutateAsync: googleRedirect,
    isPending: isGoogleRedirectPending,
    error: googleRedirectError,
    isError: isGoogleRedirectError,
  } = useMutation({
    mutationFn: (session) => googleCallbackFetchData(session),
    onError: (err) => {
      return <>Error</>;
    },

    onSuccess: (response) => {
      console.log();
      queryClient.setQueriesData({ queryKey: ["userDetails"] }, null);
      queryClient.invalidateQueries({ queryKey: ["userDetails"] });
      console.log("passed login invalidateQueries");

      navigate("/events");
    },
  });

  useEffect(() => {
    const handleOAuthRedirect = async () => {
      // const { data, error } = await supabase.auth.getSession();
      const { access_token, refresh_token } = parseHashParams();

      const session = {
        accessToken: access_token,
        refreshToken: refresh_token,
      };
      googleRedirect(session);
    };

    handleOAuthRedirect();
  }, []);

  return <Loading />;
}
